import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
// import Swal from "sweetalert2";
// import withReactContent from "sweetalert2-react-content";
import { toast } from "react-toastify";

import axios from "../../../../../axios-instance";
import Page404 from "../../../../../components/Page404/Page404";

import { getTicket } from "../../../../../store/actions/eventsActions";

import "./TicketActions.sass";
import Upload from "../../../../Upload/Upload";
import { getHeaders } from "../../../../../helpers/getHeaders";

// const MySwal = withReactContent(Swal);

class SendBuyEmail extends Component {
  state = {
    emailTemplate: null,
    file: [],
    isFound: true,
  };

  componentDidMount() {
    const {
      user: { token },
      match: { params },
      getTicket,
    } = this.props;

    if (params.id) {
      getTicket(params.ticket_id, token).then((result) => {
        if (!result.isFound) {
          this.setState({ isFound: false });
        }
      });
    }
  }

  onButtonEnable = (file) => {
    this.setState({
      file,
    });
  };
  handleSubmit = async () => {
    //TODO: Should use redux!!!
    try {
      const {
        match: { params },
      } = this.props;

      const user = JSON.parse(localStorage.getItem("user"));

      const { file } = this.state;

      const body = new FormData();
      body.append("file", file[0]);

      const { data } = await axios.post(
        `/ManageTransactions/SaveTransactions?eventId=${params.id}`,
        body,
        {
          headers: getHeaders(user.token),
        }
      );

      if (data) {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "SendOffer.csv");
        document.body.appendChild(link);
        link.click();
      }

      toast.success("File uploaded successfully");
    } catch (e) {
      toast.error("Something went wrong. Try later");
      console.log(e);
    }
  };

  checkIsDisabled = () => {
    const { file } = this.state;

    return !file || !file.length;
  };

  render() {
    if (!this.state.isFound) {
      return <Page404 />;
    }

    return (
      <div className="new-ticket-action">
        <div className="new-ticket-action__upload">
          <div className="new-ticket-action__download">
            <form
              action={
                process.env.API_URL.substring(
                  0,
                  process.env.API_URL.length - 2
                ) + "Files/Static/csv/SendOffer.csv"
              }
              method="GET"
            >
              <button>Download template</button>
            </form>
          </div>

          <div className="new-ticket-action__choose-file">
            <p>Choose the file ( .csv)</p>

            <Upload onButtonEnable={this.onButtonEnable} />
          </div>
        </div>

        <button
          type="button"
          disabled={this.checkIsDisabled()}
          onClick={this.handleSubmit}
          className="new-ticket-action__submit btn-primary"
        >
          Send
        </button>
      </div>
    );
  }
}

const mapStateToProps = ({
  auth: { user },
  events: { currentEvent, currentTicket },
}) => ({ user, currentEvent, currentTicket });

const mapDispatchToProps = (dispatch) => ({
  getTicket: (data, token) => dispatch(getTicket(data, token)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SendBuyEmail)
);
