import { combineReducers } from "redux";
import * as actionTypes from "../actionTypes/authTypes";

import auth from "./authReducer";
import events from "./eventsReducer";
import barCodes from "./barCodesReducer";
import promoters from "./promotersReducer";
import scanteams from "./scanteamsReducer";
import payments from "./paymentReducer";
import canvas from "./canvasReducer";
import ticketGeneration from "./ticketGenerationReducer";
import eventData from "./EventDataReducer";
import { ticketsImport } from "./ticketsImportReducer";
import transactions from "./transactionsReducer";
import statistics from "./statisticsReducer";
import member from "./memberReducer";
import emailTemplates from "./emailTemplatesReducer";

const appReducer = combineReducers({
  auth,
  events,
  ticketGeneration,
  canvas,
  emailTemplates,
  barCodes,
  promoters,
  scanteams,
  payments,
  eventData,
  ticketsImport,
  transactions,
  statistics,
  member,
});

const rootReducer = (state, action) => {
  if (action.type === actionTypes.LOGOUT_SUCCESS) {
    state = null;
  }

  return appReducer(state, action);
};

export default rootReducer;
