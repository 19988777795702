import React, { Component } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import AsyncSelect from "react-select/lib/Async";
import moment from "moment";
import DatePicker from "react-datepicker/es";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import withReactContent from "sweetalert2-react-content";

import {
  addEvent,
  getEvent,
  getSeasons,
  resetEventsError,
} from "../../../store/actions/eventsActions";
import { currencies } from "../../../constants/currencies";

import { Counter } from "../../../components/Counter/Counter";
import { salesAvailabilityOptions } from "../../../constants/salesAvailabilityOptions";
import { makeElementsReadonly } from "../../../helpers/html";
import { getTimeZoneAsyncFunc, emptyTimeZoneOption } from "../../../helpers/getTimeZoneAsync";
import { getISODateWithTimezoneOffset } from "../../../helpers/date";
import ROUTES from "../../../constants/routes";

import "react-datepicker/dist/react-datepicker.css";
import "./AddEvent.sass";

const MySwal = withReactContent(Swal);

class AddEvent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      startDate: null,
      endDate: null,
      currency: {},
      slug: (Math.random() + 1).toString(32).substring(6),
      isCheckingCovid: false,
      salesAvailability: salesAvailabilityOptions[0],
      activationTime: null,
      maxSeatsToSelectPerTransaction: 4,
      seasonValue: { value: 0, label: "No Season" },
      ticketEmailValue: { value: 0, label: "Default" },
      offerEmailValue: { value: 0, label: "Default" },
      guestEmailValue: { value: 0, label: "Default" },
      errorEmailValue: { value: 0, label: "Default" },
      invoiceEmailValue: { value: 0, label: "Default" },
      qrCodeVisibilityHours: 0,
      timeZoneOption: emptyTimeZoneOption,
    };
  }

  inputChangeHandler = (event) => {
    let { name, value } = event.target;

    if (name === "fixedFeeRate" || name === "extraFeeRate") {
      if (value[0] === "-") {
        value = "-" + value.replace(/[^\d.]/g, '');
      } else {
        value = value.replace(/[^\d.]/g, '');
      };

      if (name === "extraFeeRate" && (+value > 100 || +value < -100)) {
        return;
      }
    };

    this.setState({ [name]: value });
  };

  handleChange = (type, value) => {
    this.setState({ [type]: value });

    if (type === "startDate" && value) {
      const startDate = moment(value).add(1, "day").toDate();
      this.setState({
        endDate: startDate,
      });
    }
  };

  inputTimeZoneChangeHandle = (value) => {
    this.setState({ timeZoneOption: value, isDisabled: false })
  }

  setMaxSeatsToSelectPerTransaction = (value) =>
    this.setState({
      maxSeatsToSelectPerTransaction: value,
    });
  getSeasons = () => {
    const user = JSON.parse(localStorage.getItem("user"));

    return () =>
      this.props.getSeasons(user.token).then((data) => [
        { value: 0, label: "No Season" },
        ...data.map(({ id, seasonName }) => ({
          value: id,
          label: seasonName,
        })),
      ]);
  };

  componentDidMount() {
    // User can't enter text in Start and End date pickers
    makeElementsReadonly("react-datepicker__input-container");
  }

  componentWillUnmount() {
    this.props.resetEventsError();
  }

  addEvent = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    const {
      name,
      startDate,
      endDate,
      currency,
      isCheckingCovid,
      slug,
      salesAvailability,
      activationTime,
      place,
      prefixLabel,
      maxSeatsToSelectPerTransaction,
      seasonValue,
      ticketEmailValue,
      offerEmailValue,
      guestEmailValue,
      errorEmailValue,
      invoiceEmailValue,
      qrCodeVisibilityHours,
      fixedFeeRate,
      extraFeeRate,
    } = this.state;
    const { addEvent, getEvent } = this.props;

    const isoStartDate = getISODateWithTimezoneOffset(startDate);
    const isoEndDate = getISODateWithTimezoneOffset(endDate);
    const isoActivationTime = activationTime
      ? getISODateWithTimezoneOffset(activationTime)
      : null;

    const data = {
      activationTime: isoActivationTime,
      salesAvailability: salesAvailability.value,
      name,
      slug,
      prefixLabel,
      startDate: isoStartDate,
      endDate: isoEndDate,
      currency: currency.value,
      isCheckingCovid,
      place: place,
      maxSeatsToSelectPerTransaction: maxSeatsToSelectPerTransaction,
      seasonId: seasonValue.value,
      emailTicketTemplateId: ticketEmailValue.value,
      emailOfferTemplateId: offerEmailValue.value,
      emailGuestTemplateId: guestEmailValue.value,
      emailErrorTemplateId: errorEmailValue.value,
      emailInvoiceTemplateId: invoiceEmailValue.value,
      qrCodeVisibilityHours: qrCodeVisibilityHours,
      fixedFeeRate: fixedFeeRate || 0,
      extraFeeRate: (+extraFeeRate / 100) || 0,
    };

    if (data.name.length < 2 || data.name.length > 500) {
      MySwal.fire({
        title: "Error!",
        text: "The field Name must be a string with a minimum length of 2 and a maximum length of 500.",
        showConfirmButton: true,
        confirmButtonText: "Ok",
        type: "error",
        timer: 3000,
      });
    } else if (data.slug.length < 2 || data.slug.length > 30) {
      MySwal.fire({
        title: "Error!",
        text: "The field slug must be a string with a minimum length of 2 and a maximum length of 30.",
        showConfirmButton: true,
        confirmButtonText: "Ok",
        type: "error",
        timer: 3000,
      });
    } else {
      addEvent(data, user.token)
        .then((event) => {
          getEvent(event.id, user.token)
        });
    }
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.events.error &&
      this.props.events.error !== prevProps.error
    ) {
      toast.error(String(this.props.events.error));

      this.props.resetEventsError();
    };

    const id = this.props.events.currentEvent.id;

    if (this.props.events.currentEvent.id) {
      this.props.history.push({
        pathname: ROUTES.NEW_EVENT_INFO.replace(":id", id),
      })
    }
  }

  render() {
    const {
      name,
      startDate,
      endDate,
      currency,
      slug,
      place,
      prefixLabel,
      salesAvailability,
      activationTime,
      maxSeatsToSelectPerTransaction,
      seasonValue,
      timeZoneOption,
      fixedFeeRate,
      extraFeeRate,
    } = this.state;

    const customSelectStyles = {
      control: (base) => ({
        ...base,
        height: "46px",
        "min-height": "46px",
      }),
      placeholder: (base) => ({
        ...base,
        color: "#ABABAB",
      }),
    };

    return (
      <div className="add-event">
        <div>
          <div className="add-event__row">
            <div className="add-event__column">
              <div className="add-event__block">
                <label>Event name</label>
                <input
                  type="text"
                  name="name"
                  minLength="3"
                  maxLength="500"
                  placeholder="Enter event name"
                  onChange={this.inputChangeHandler}
                />
              </div>
            </div>
            <div className="add-event__column">
              <div className="add-event__block">
                <label>Venue</label>
                <input
                  type="text"
                  name="place"
                  minLength="3"
                  maxLength="500"
                  placeholder="Enter venue"
                  onChange={this.inputChangeHandler}
                />
              </div>
            </div>
            <div className="add-event__column">
              <div className="add-event__block">
                <label>Season</label>
                <AsyncSelect
                  cacheOptions
                  defaultOptions
                  loadOptions={this.getSeasons()}
                  value={seasonValue}
                  isSearchable={false}
                  onChange={(value) => this.handleChange("seasonValue", value)}
                  classNamePrefix="custom-select"
                  className="custom-select"
                />
              </div>
            </div>
          </div>

          <div className="add-event__row table">
            <div className="add-event__column">
              <div className="add-event__block">
                <label>Start</label>
                <DatePicker
                  selected={startDate}
                  onChange={(event) => {
                    this.handleChange("startDate", event);
                  }}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  dateFormat="h:mm a d MMM yyyy "
                  timeCaption="time"
                  placeholderText="Select start date"
                />
              </div>
            </div>

            <div className="add-event__column">
              <div className="add-event__block">
                <label>End</label>
                <DatePicker
                  selected={endDate}
                  onChange={(value) => {
                    this.handleChange("endDate", value);
                  }}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  dateFormat="h:mm a d MMM yyyy "
                  timeCaption="time"
                  placeholderText="Select end date"
                />
              </div>
            </div>

            <div className="add-event__column">
            <div className="add-event__block">
              <label>Time Zone</label>

              <AsyncSelect
                cacheOptions
                defaultOptions
                loadOptions={getTimeZoneAsyncFunc()}
                value={timeZoneOption}
                onChange={this.inputTimeZoneChangeHandle}
                classNamePrefix='custom-select'
                className='custom-select'
              />
            </div>
          </div>
          </div>

          <div className="add-event__row add-event__row--width-divider">
            <div className="add-event__column">
              <div className="add-event__block">
                <label>Currency</label>
                <Select
                  options={currencies}
                  classNamePrefix="add-event__select"
                  onChange={(value) => {
                    this.handleChange("currency", value);
                  }}
                  className="add-event__select"
                  styles={customSelectStyles}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: "#6071B5",
                      primary25: "#F2F4FE",
                      primary50: "#F2F4FE",
                      primary75: "#F2F4FE",
                    },
                  })}
                  placeholder="Select currency"
                />
              </div>
            </div>

            <div className="add-event__column">
              <div className="add-event__block">
                <label>Slug</label>
                <input
                  type="text"
                  name="slug"
                  minLength="3"
                  maxLength="30"
                  value={slug}
                  placeholder="Enter slug"
                  onChange={this.inputChangeHandler}
                />
              </div>
            </div>
          </div>

          <div className="add-event__row">
            <div className="add-event__column">
              <div className="add-event__block">
                <label>Sales availability</label>
                <Select
                  options={salesAvailabilityOptions}
                  name="salesAvailability"
                  classNamePrefix="add-event__select"
                  value={salesAvailability}
                  defaultValue={salesAvailability}
                  onChange={(value) => {
                    this.handleChange("salesAvailability", value);
                  }}
                  className="add-event__select"
                  styles={customSelectStyles}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: "#6071B5",
                      primary25: "#F2F4FE",
                      primary50: "#F2F4FE",
                      primary75: "#F2F4FE",
                    },
                  })}
                />
              </div>
            </div>

            <div className="add-event__column">
              <div className="add-event__block">
                <label>Sales start time</label>
                <DatePicker
                  selected={activationTime}
                  onChange={(value) => {
                    this.handleChange("activationTime", value);
                  }}
                  timeFormat="HH:mm"
                  showTimeSelect
                  timeIntervals={15}
                  dateFormat="h:mm a d MMM yyyy "
                  timeCaption="time"
                  placeholderText="Select activation time"
                />
              </div>
            </div>
          </div>

          <div className="add-event__row">
            <div className="add-event__column">
              <div className="add-event__block">
                <Counter
                  label="Number of tickets in one transaction"
                  value={maxSeatsToSelectPerTransaction}
                  onValueChange={this.setMaxSeatsToSelectPerTransaction}
                />
              </div>
            </div>

            <div className="add-event__column">
              <div className="add-event__block">
                <label>Category</label>
                <input
                  type="text"
                  name="prefixLabel"
                  minLength="3"
                  maxLength="500"
                  placeholder="Enter event category"
                  onChange={this.inputChangeHandler}
                />
              </div>
            </div>

            <div className="add-event__column">
              <div className="add-event__block">
                <Counter
                  min="0"
                  label={this.state.qrCodeVisibilityHours > 0 ? `QR-code will be visible in ${this.state.qrCodeVisibilityHours} hours before event` : "QR-code will be visible any time"}
                  value={this.state.qrCodeVisibilityHours}
                  onValueChange={(value) =>
                    this.inputChangeHandler(
                      {
                        target: {
                          name: "qrCodeVisibilityHours",
                          value
                        }
                      }
                    )
                  }
                />
              </div>
            </div>
          </div>
        </div>

        <div className="add-event__row">
          <div className="add-event__column">
            <div className="add-event__block">
              <label>Fixed fee rate, {currency.label || "select currency"}</label>
              <input
                type="text"
                name="fixedFeeRate"
                value={fixedFeeRate}
                minLength="3"
                maxLength="500"
                placeholder="Enter fixed fee rate"
                onChange={this.inputChangeHandler}
              />
            </div>
          </div>

          <div className="add-event__column">
            <div className="add-event__block">
              <label>Extra fee rate, %</label>
              <input
                type="text"
                name="extraFeeRate"
                value={extraFeeRate}
                minLength="3"
                maxLength="500"
                placeholder="Enter extra fee rate"
                onChange={this.inputChangeHandler}
              />
            </div>
          </div>
        </div>

        <div className="page-footer">
          <button
            type="button"
            className="btn-primary"
            onClick={this.addEvent}
            disabled={
              !name ||
              !startDate ||
              !endDate ||
              !salesAvailability ||
              !currency ||
              !slug ||
              !place ||
              !prefixLabel ||
              maxSeatsToSelectPerTransaction < 1 ||
              fixedFeeRate === "-" ||
              extraFeeRate === "-"
            }
          >
            Add event
          </button>
        </div>
      </div>
    );
  }
}

AddEvent.propTypes = {};

const mapDispatchToProps = (dispatch) => ({
  addEvent: (data, userToken) => dispatch(addEvent(data, userToken)),
  getEvent: (eventId, token) => dispatch(getEvent(eventId, token)),
  resetEventsError: () => dispatch(resetEventsError()),
  getSeasons: (userToken) => dispatch(getSeasons(userToken)),
});

export default connect(
  ({ events }) => ({ events }),
  mapDispatchToProps
)(AddEvent);
