import React, { Component } from "react";
import { connect } from "react-redux";
import withRouter from "react-router/es/withRouter";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";

import {
  getEvent,
  getEventScanStats,
  getEventSellStats,
} from "../../../../../store/actions/eventsActions";
import browserTabTitleOverride from "../../../../../helpers/tabTitleOverride";
import SellStatisticForm from "./sell/SellStatisticForm";
import ScanStatisticForm from "./scan/ScanStatisticForm";

import "./EventStatistics.sass";
import "react-tabs/style/react-tabs.css";
import NewLoader from "../../../../../components/NewLoader/NewLoader";


class StatisticPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isScanOpened: false,
    };
  }

  componentDidMount() {
    browserTabTitleOverride();
    const {
      token,
      match: { params },
      currentEvent,
    } = this.props;
    let event_id = params.event_id || params.id;

    // this.props.getEventSellStats(event_id, token);

    if (!currentEvent.id) {
      this.props.getEvent(event_id, token).then((res) => {
        if (res.isFound) {
          this.props.getEventSellStats(event_id, token);
        }
      });
    } else {
      this.props.getEventSellStats(event_id, token);
    }
  }

  handleScanClick = () => {
    if (!this.state.isScanOpened) {
      const {
        token,
        match: { params },
        currentEvent,
      } = this.props;
      let event_id = params.event_id || params.id;

      /* this.props.getEventScanStats(event_id, token);
      this.setState({ isScanOpened: true }); */

      if (!currentEvent.id) {
        this.props
          .getEvent(event_id, token)
          .then((res) => {
            if (res.isFound) {
              this.props.getEventScanStats(event_id, token);
            }
          })
          .then(() => this.setState({ isScanOpened: true }));
      } else {
        this.props.getEventScanStats(event_id, token);
        this.setState({ isScanOpened: true });
      }
    }
  };

  render() {
    const { currentEvent, isLoading } = this.props;

    if (isLoading) {
      return (
        <div className="new-event__stats statistics">
          <NewLoader />
        </div>
      )
    };

    return (
      <div className="new-event__stats statistics">
        <Tabs className="statistics__tabs">
          <TabList>
            <Tab>Sell</Tab>
            <Tab onClick={this.handleScanClick}>Scan</Tab>
          </TabList>
          <TabPanel>
            <SellStatisticForm id={currentEvent.id} />
          </TabPanel>
          <TabPanel>
            <ScanStatisticForm id={currentEvent.id} />
          </TabPanel>
        </Tabs>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.user.token,
    currentEvent: state.events.currentEvent,
    isLoading: state.events.loading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getEvent: (eventId, token) => dispatch(getEvent(eventId, token)),
  getEventSellStats: (eventId, token) =>
    dispatch(getEventSellStats(eventId, token)),
  getEventScanStats: (eventId, token) =>
    dispatch(getEventScanStats(eventId, token)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(StatisticPage));
