import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import Select from 'react-select';
import ReactTable from 'react-table';
import withFixedColumns from 'react-table-hoc-fixed-columns';
import swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { toast } from 'react-toastify';
import debounce from 'lodash/debounce';
import axios from '../../../../../axios-instance';
import NewLoader from '../../../../../components/NewLoader/NewLoader';
import { getHeaders } from '../../../../../helpers/getHeaders';
import { ManagementAction } from './ManagementAction.js';
import { loadFile } from '../../../../../helpers/loadFile';
import PleaseWaitModal from '../../../../BarCodes/modals/PleaseWaitModal';
import { IconButton } from "../../../../../components/UI/IconButton/IconButton";

import '../EventTransactions/EventTransactions.sass';
import './Management.sass';
import { ActionTicketModal } from './ActionTicketModal.js';

const MySwal = withReactContent(swal);

export const statuses = [
  { value: 1, label: 'Active' },
  { value: 2, label: 'Cancelled' },
  { value: 3, label: 'Refunded' },
  { value: 4, label: 'Resold' },
  { value: 5, label: 'Lost' },
];

const EventManagment = (params) => {
  const token = params.token;
  const initInputParams = {
    query: '',
    area: '',
    side: '',
    row: '',
    seat: '',
  };

  const [data, setData] = useState([]);
  const [ticketTemplates, setTicketTemplates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedTicketTemplate, setSelectedTicketTemplate] = useState(null);
  const [inputParams, setInputParams] = useState(initInputParams);
  const [isRequest, setRequest] = useState(false);

  const [currentPageNumber, setCurrentPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(500);
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [isClearButtonShow, setClearButtonShow] = useState(false)

  const ReactTableFixedColumns = useMemo(
    () => withFixedColumns(ReactTable),
    []
  );

  const request = async (asCSV = false) => {
    return axios
      .post(
        '/ManageTickets/List',
        {
          searchQuery: inputParams.query,
          eventId: params.match.params.id,
          status: selectedStatus ? selectedStatus.value : null,
          ticketTemplateId: selectedTicketTemplate
            ? selectedTicketTemplate.value
            : null,
          area: inputParams.area,
          side: inputParams.side,
          row: inputParams.row,
          seat: inputParams.seat,
          pageSize: 1000000,
          page: 0,
          asCSV,
        },
        {
          headers: getHeaders(token),
        }
      )
      .catch((e) => {
        toast.error('Internal Server Error');
      });
  };

  const getData = async () => {
    setLoading(true);

    try {
      const { data } = await request();

      setData(data.list);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const handleChangeStatus = (item) => {
    setSelectedStatus(item);
    setRequest(true);
  };
  const handleChangeTemplate = (item) => {
    setSelectedTicketTemplate(item);
    setRequest(true);
  };

  const handleDebonceFn = () => {
    setRequest(true);
  };

  const debounceFn = useCallback(debounce(handleDebonceFn, 1500), []);

  const handleChangeInputParams = (key, value) => {
    setInputParams((prev) => {
      return {
        ...prev,
        [key]: value,
      };
    });

    debounceFn();
  };

  const clearSeat = () => {
    setInputParams((prev) => ({
      ...initInputParams,
      query: prev.query
    }));
    setRequest(true);
    setClearButtonShow(false);
  }

  const handleCurrentPageChanged = (value) => {
    setCurrentPageNumber(value);
  };

  const handlePageSizeChanged = (value) => {
    setPageSize(value);
  };

  const goToCurrentPage = () => {
    const start = currentPageNumber * pageSize;
    const end = (currentPageNumber + 1) * pageSize;

    setDataToDisplay(data.slice(start, end));
  };

  const refreshDataToDisplay = () => {
    if (currentPageNumber === 0) {
      goToCurrentPage();
    } else {
      setCurrentPageNumber(0);
    }
  };

  const showRefundTicketModal = (ticket) => {
    MySwal.fire({
      title: 'Refund ticket',
      html: (
        <ActionTicketModal
          ticket={ticket}
          token={token}
          actionWordList={['refund', 'refunded']}
          endpoint={'/ManageTickets/Refund'}
          getData={getData}
          setLoading={setLoading}
        />
      ),
      showConfirmButton: false,
      customClass: 'refund-tickets-modal',
    });
  };

  const cancelTicketHandler = (ticket) => {
    MySwal.fire({
      title: 'Cancel ticket',
      html: (
        <ActionTicketModal
          ticket={ticket}
          token={token}
          actionWordList={['cancel', 'cancelled']}
          endpoint={'/ManageTickets/CancelTickets'}
          getData={getData}
          setLoading={setLoading}
        />
      ),
      showConfirmButton: false,
      customClass: 'refund-tickets-modal',
    });
  };

  const lostTicket = async (ticketId, userToken) => {
    setLoading(true);
    return axios
      .post(`/ManageTickets/Lost/` + ticketId, null, {
        headers: getHeaders(userToken),
      })
      .then((response) => {
        if (response.status === 200) {
          toast.success('Ticket successfully regenerated');
          return;
        }

        throw new Error(response.data.errorMessage);
      })
      .catch((err) => {
        toast.error('Ticket not marked as loat and barcode not regenerated!');
      })
      .finally(() => setLoading(false));
  };

  const changeNameHandler = async (ticket) => {
    const { value: fullName } = await MySwal.fire({
      title: 'Change ticket holder name',
      input: 'text',
      inputLabel: 'Current full name: ' + ticket.fullName,
      inputPlaceholder: 'Current full name: ' + ticket.fullName,
      confirmButtonText: 'Change',
      showCancelButton: true,
      cancelButtonText: 'Cancel',
      reverseButtons: true,
    });

    if (fullName) {
      setLoading(true);

      axios
        .put(
          '/ManageTickets',
          { ticketId: ticket.id, fullName },
          { headers: getHeaders(token) }
        )
        .then((response) => {
          if (response.status === 200) {
            if (response.data) {
              toast.success('Name successfully changed');

              getData();

              return;
            } else {
              throw new Error(response.data.errorMessage);
            }
          }
        })
        .catch((err) => {
          toast.error('Name not changed');
        })
        .finally(() => setLoading(false));
    }
  };

  const lostTicketHandler = async (ticket) => {
    MySwal.fire({
      title: 'Are you sure you want to regenerate barcode for the ticket?',
      text: "It's impossible to take step back then",
      showCancelButton: true,
      cancelButtonText: 'Cancel',
      confirmButtonText: 'Confirm',
      reverseButtons: true,
      customClass: 'confirm-lost-ticket-modal',
    }).then((result) => {
      if (result.value) {
        lostTicket(ticket.id, token).then(() => getData());
      }
    });
  };

  const changeSeatActionHandler = async (ticket) => {
    const { value: complexSeat } = await MySwal.fire({
      title: 'Change ticket seat',
      input: 'text',
      inputLabel:
        'Current seat: ' +
        ticket.area +
        '-' +
        ticket.side +
        '-' +
        ticket.row +
        '-' +
        ticket.seat,
      inputPlaceholder:
        'Current seat: ' +
        ticket.area +
        '-' +
        ticket.side +
        '-' +
        ticket.row +
        '-' +
        ticket.seat,
      confirmButtonText: 'Change',
      showCancelButton: true,
      cancelButtonText: 'Cancel',
      reverseButtons: true,
    });

    if (complexSeat) {
      let [seat, row, side, area] = complexSeat.split('-').reverse();

      if (!area) {
        area = side;
        side = undefined;
      }

      area = !!area ? area.toUpperCase() : area;
      side = !!side ? side.toUpperCase() : side;
      row = !!row ? row.toUpperCase() : row;
      seat = !!seat ? seat.toUpperCase() : seat;

      const changeSeatRequest = async (isAllowSwap = false) => {
        setLoading(true);

        return axios
          .put(
            '/ManageTickets/ChangeSeat',
            {
              ticketId: ticket.id,
              area: area,
              side: side,
              row: row,
              seat: seat,
              allowSwap: isAllowSwap,
            },
            { headers: getHeaders(token) }
          )
          .catch((err) => {
            toast.error('Seat not changed');
          })
          .finally(() => setLoading(false));
      };

      changeSeatRequest()
        .then((response) => {
          if (response.data.isSuccess) {
            toast.success('Seat successfully changed');

            getData();

            return;
          } else if (response.data.errorMessage === 'Seat is occupied') {
            MySwal.fire({
              title: response.data.errorMessage,
              text: 'Do you want to swap tickets with another seat?',
              showCancelButton: true,
              cancelButtonText: 'Cancel',
              confirmButtonText: 'Swap',
              reverseButtons: true,
              customClass: 'confirm-cancel-offer-modal',
            }).then((result) => {
              if (result.value) {
                changeSeatRequest(true).then(() => {
                  toast.success('Seat successfully changed');
                  getData();

                  return;
                });
              }
            });
          } else {
            toast.error('Seat not changed');

            throw new Error(response.data.errorMessage);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const downloadDataAsFile = (data, name) => {
    if (data) {
      loadFile(data, name);

      toast.success('File uploaded successfully');
    } else {
      toast.error('Failed to retrieve data');
    }
  };

  const downloadReportHandler = async () => {
    try {
      try {
        MySwal.fire({
          html: <PleaseWaitModal message='Downloading file…' />,
          customClass: 'please-wait-modal',
        });

        const { data } = await request(true);

        downloadDataAsFile(data, `Tickets Report.csv`);
      } finally {
        MySwal.close();
      }
    } catch (e) {
      toast.error('File was not downloaded');
      console.log(e);
    }
  };

  useEffect(() => {
    refreshDataToDisplay();

    if (params.currentEvent.id) {
      const templates = params.currentEvent.ticketTemplates;
      const ticketTemplatesForSelect = templates.map((t) => ({
        value: t.id,
        label: t.ticketName,
      }));
      setTicketTemplates(ticketTemplatesForSelect);
    }
  }, [data, pageSize]);

  useEffect(() => {
    goToCurrentPage();
  }, [currentPageNumber]);

  useEffect(() => {
    if (
      inputParams.area || inputParams.row ||
      inputParams.seat || inputParams.side
    ) {
      setClearButtonShow(true);
    } else {
      setClearButtonShow(false)
    };

    if (isRequest) {
      getData();
      setRequest(false);
    };
  }, [selectedStatus, selectedTicketTemplate, isRequest, inputParams]);

  useEffect(() => {
    if (params.currentEvent.id) {
      getData();
    }
  }, [params.currentEvent.id]);

  const getLabelStyle = () => ({
    style: {
      marginTop: '6px',
    },
  });

  const getFlexStyle = () => ({
    style: {
      padding: "3px 5px 0",
      display: "flex",
      alignItems: "center",
    },
  });

  const customSelectStyles = {
    control: (base) => ({
      ...base,
      height: '46px',
      'min-height': '46px',
      cursor: 'pointer',
    }),
    placeholder: (base) => ({
      ...base,
      color: '#ABABAB',
    }),
  };

  /*if (!data.length && !loading) {
    return <Page404 />;
  }*/

  const columns = [
    {
      Header: 'Ticket Type',
      accessor: 'ticketName',
      width: 160,
      resizable: false,
      getProps: getLabelStyle,
      Cell: ({
        row: {
          _original: { ticketName },
        },
      }) => <span title={ticketName}>{ticketName}</span>,
    },
    {
      Header: 'Seat',
      resizable: false,
      width: 100,
      getProps: getLabelStyle,
      Cell: ({
        row: {
          _original: { area, side, row, seat },
        },
      }) => {
        if (area && side && row && seat) {
          return `${area}-${side}-${row}-${seat}`;
        }
        return '';
      },
      getLabelStyle,
    },
    {
      Header: 'Barcode',
      accessor: 'barcode',
      resizable: false,
      minWidth: 120,
      getProps: getLabelStyle,
      Cell: ({
        row: {
          _original: { barcode },
        },
      }) => <span title={barcode}>{barcode}</span>,
    },
    {
      Header: 'Full Name',
      accessor: 'fullName',
      resizable: false,
      width: 220,
      getProps: getLabelStyle,
      Cell: ({
        row: {
          _original: { fullName },
        },
      }) => <span title={fullName}>{fullName}</span>,
    },
    {
      Header: 'Email',
      accessor: 'customerEmail',
      resizable: false,
      width: 220,
      getProps: getLabelStyle,
      Cell: ({
        row: {
          _original: { customerEmail },
        },
      }) => <span title={customerEmail}>{customerEmail}</span>,
    },
    {
      Header: 'Status',
      resizable: false,
      width: 110,
      getProps: getFlexStyle,
      Cell: ({
        row: {
          _original: { status },
        },
      }) => {
        const currStatus = statuses.find((s) => s.value === status);
        if (currStatus)
          return (
            <div className={`status-label status-label--${status}`}>
              {currStatus.label}
            </div>
          );
        return '';
      },
    },
    {
      Header: 'Scans count',
      resizable: false,
      width: 110,
      Cell: ({
        row: {
          _original: { scansCount },
        },
      }) => {
        return <span>{scansCount > 0 ? scansCount : 'Not scanned'}</span>;
      },
      getProps: getLabelStyle,
    },
    {
      Header: () => null,
      Cell: ({ row: { _original: data } }) => {
        return (
          <ManagementAction
            ticket={data}
            showRefundTicketModal={showRefundTicketModal}
            cancelTicketHandler={cancelTicketHandler}
            changeNameHandler={changeNameHandler}
            lostTicketHandler={lostTicketHandler}
            changeSeatHandler={changeSeatActionHandler}
          />
        );
      },
      width: 50,
      getProps: getFlexStyle,
      fixed: 'right',
    },
  ];

  return (
    <div className='transactions management'>
      <div className='management__searchPanels'>
        <div className='management__searchPanel searchPanel'>
          <Select
            isClearable
            options={statuses}
            placeholder='Select status'
            name='searchProfileStatus'
            classNamePrefix='custom-select'
            value={selectedStatus}
            onChange={handleChangeStatus}
            className='seasonpass__select searchPanel__select'
            styles={customSelectStyles}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: '#6071B5',
                primary25: '#F2F4FE',
                primary50: '#F2F4FE',
                primary75: '#F2F4FE',
              },
            })}
          />
          <input
            className='searchPanel__search input-behavior'
            type='text'
            placeholder='Search'
            value={inputParams.query}
            onChange={(e) => handleChangeInputParams('query', e.target.value)}
          />
          <button
            className='btn-primary-new admin'
            onClick={downloadReportHandler}
          >
            Download Report
          </button>
        </div>

        <div className='management__searchPanel'>
          <Select
            isClearable
            options={ticketTemplates}
            placeholder='Ticket type'
            name='searchProfileStatus'
            classNamePrefix='custom-select'
            value={selectedTicketTemplate}
            onChange={handleChangeTemplate}
            className='seasonpass__select searchPanel__select'
            styles={customSelectStyles}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: '#6071B5',
                primary25: '#F2F4FE',
                primary50: '#F2F4FE',
                primary75: '#F2F4FE',
              },
            })}
          />
          <input
            className='searchPanel__search-small input-behavior'
            type='text'
            placeholder='Area'
            value={inputParams.area}
            onChange={(e) => handleChangeInputParams('area', e.target.value)}
          />
          <input
            className='searchPanel__search-small input-behavior'
            type='text'
            placeholder='Side'
            value={inputParams.side}
            onChange={(e) =>
              handleChangeInputParams('side', e.target.value.toUpperCase())
            }
          />
          <input
            className='searchPanel__search-small input-behavior'
            type='text'
            placeholder='Row'
            value={inputParams.row}
            onChange={(e) =>
              handleChangeInputParams('row', e.target.value.toUpperCase())
            }
          />
          <input
            className='searchPanel__search-small input-behavior'
            type='text'
            placeholder='Seat'
            value={inputParams.seat}
            onChange={(e) => handleChangeInputParams('seat', e.target.value)}
          />

          {isClearButtonShow && (
            <div
              className="searchPanel__clear-wrapper"
              title="Clear Seat"
            >
              <IconButton
                iconName={"clear"}
                className="searchPanel__clear"
                onClick={clearSeat}
              />
            </div>
          )}
        </div>
      </div>

      {loading ? (
        <div className='management__loader'>
          <NewLoader />
        </div>
      ) : data.length ? (
        <>
          <ReactTableFixedColumns
            data={dataToDisplay}
            columns={columns}
            manual
            showPagination={true}
            pageSizeOptions={[100, 200, 500, 1000, 100000]}
            minRows={0}
            page={currentPageNumber}
            pageSize={pageSize}
            pages={Math.ceil(data.length / pageSize)}
            onPageChange={handleCurrentPageChanged}
            onPageSizeChange={handlePageSizeChanged}
            sortable={false}
            getTrProps={() => ({
              style: {
                alignItems: 'stretch',
              },
            })}
          />
        </>
      ) : (
        <div className='events__empty-state'>
          <img src='/images/img-empty-events.svg' alt='Empty membership' />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = ({ auth, events: { currentEvent } }) => ({
  auth,
  token: auth.user.token,
  currentEvent,
});

const mapDispatchToProps = (dispatch) => ({});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EventManagment)
);
