// canvasActionTypes.js
export const APPEND_RECTANGLE_OBJECT = "APPEND_RECTANGLE_OBJECT";
export const APPEND_ELLIPSE_OBJECT = "APPEND_ELLIPSE_OBJECT";
export const APPEND_FREE_DRAW_OBJECT = "APPEND_FREE_DRAW_OBJECT";
export const APPEND_TEXT_OBJECT = "APPEND_TEXT_OBJECT";
export const APPEND_ICON_OBJECT = "APPEND_ICON_OBJECT";
export const APPEND_IMAGE_OBJECT = "APPEND_IMAGE_OBJECT";
export const APPEND_QRCODE_OBJECT = "APPEND_QRCODE_OBJECT";
export const APPEND_FOOTER_OBJECT = "APPEND_FOOTER_OBJECT";
export const UPDATE_CANVAS_OBJECT = "UPDATE_CANVAS_OBJECT";
export const APPEND_FREE_DRAW_POINT_TO_CANVAS_OBJECT =
  "APPEND_FREE_DRAW_POINT_TO_CANVAS_OBJECT";
export const DELETE_CANVAS_OBJECT = "DELETE_CANVAS_OBJECT";
export const MOVE_CANVAS_OBJECT = "MOVE_CANVAS_OBJECT";
export const RESIZE_CANVAS_OBJECT = "RESIZE_CANVAS_OBJECT";
export const SET_CANVAS_OBJECT_LAYER_INDEX = "SET_CANVAS_OBJECT_LAYER_INDEX";
export const SET_CANVAS_OBJECT_LAYER_TO_TOP = "SET_CANVAS_OBJECT_LAYER_TO_TOP";
export const SET_CANVAS_OBJECT_LAYER_TO_BOTTOM =
  "SET_CANVAS_OBJECT_LAYER_TO_BOTTOM";
export const RESET_CANVAS_OBJECTS = "RESET_CANVAS_OBJECTS";

export const SET_CANVAS_LOADING = "SET_CANVAS_LOADING";

export const SET_ACTIVE_OBJECT_ID = "SET_ACTIVE_OBJECT_ID";

export const SET_CANVAS_WORKING_WIDTH = "SET_CANVAS_WORKING_WIDTH";
export const SET_CANVAS_WORKING_HEIGHT = "SET_CANVAS_WORKING_HEIGHT";

export const SET_DEFAULT_PARAMS = "SET_DEFAULT_PARAMS";

export const SET_SCROLL_POSITION = "SET_SCROLL_POSITION";
export const UPDATE_SCROLL_POSITION = "UPDATE_SCROLL_POSITION";

export const SET_USER_MODE = "SET_USER_MODE";

export const SET_ACTION_MODE = "SET_ACTION_MODE";

export const SET_TEMPLATE = "SET_TEMPLATE";

export const GET_TEMPLATES = "GET_TEMPLATES";
export const GET_TEMPLATES_SUCCESS = "SET_TEMPLATE_SUCCESS";
export const GET_TEMPLATES_FAIL = "GET_TEMPLATES_FAIL";
export const RESET_TEMPLATES_STATE = "RESET_TEMPLATES_STATE";

export const GET_TEMPLATE_TEXT_VALUES = "GET_TEMPLATE_TEXT_VALUES";
export const GET_TEMPLATE_TEXT_VALUES_SUCCESS =
  "GET_TEMPLATE_TEXT_VALUES_SUCCESS";
export const GET_TEMPLATE_TEXT_VALUES_FAIL = "GET_TEMPLATE_TEXT_VALUES_FAIL";

export const GET_TEMPLATE = "GET_TEMPLATE";
export const GET_TEMPLATE_SUCCESS = "GET_TEMPLATE_SUCCESS";
export const GET_TEMPLATE_FAIL = "GET_TEMPLATE_FAIL";

export const SAVE_TEMPLATE_SUCCESS = "SAVE_TEMPLATE_SUCCESS";

export const RESET_ZOOM = "RESET_ZOOM";
export const SET_ZOOM = "SET_ZOOM";
export const DECREMENT_ZOOM = "DECREMENT_ZOOM";
export const INCREMENT_ZOOM = "INCREMENT_ZOOM";
