import axios from "../axios-instance";

import { getHeaders } from "./getHeaders";
import { getOptionsForAsyncSelect } from "./getOptionsForAsyncSelect";

export const emptyTimeZoneOption = {
  value: "Europe/Belgrade",
  label: "Europe/Belgrade",
};

export const getTimeZoneAsyncFunc = (
  withEmptyOption = false,
  emptyOption = emptyTimeZoneOption
) => {
  const user = JSON.parse(localStorage.getItem("user"));

  return (inputValue) =>
    axios
      .get("/TimeZone", { headers: getHeaders(user.token) })
      .then(({ data }) => {
        let options = withEmptyOption ? [emptyOption] : [];

        if (data && data.length) {
          options = [
            ...getOptionsForAsyncSelect(data, {
              labelKey: "displayName",
              returnDataKey: "item",
              valueKey: "id",
            }),
          ].filter(({ label, value }) =>
            label.toLowerCase().includes(inputValue.toLowerCase()) ||
            value.toLowerCase().includes(inputValue.toLowerCase())
          )
          .map(({ value, label }) => {
            if (value === "UTC") {
              return { value, label }
            }

            return { value, label: label.slice(1, 10) + ' - ' + value }
          });
        }
        return options;
      })
      .catch((e) => {
        console.log(e);
      });
};
