export const getRegion = () => {
  const region = localStorage.getItem("region"); // dev method
  
  const host = window.location.host;

  let devUrl = null;

  if (host === "localhost:3000") {
    devUrl = "http://localhost:3000"
  }

  switch (region) { // host for prod
    case "fanceetickets":
      return {
        url: devUrl || "https://dev-app.fanceetickets.com/",
        theme: "fanceetickets",
        title: "FanceeTickets",
      };

    case "sixtix":
      return {
        url: devUrl || "https://dev-app.fanceetickets.com/",
        theme: "sixtix",
        title: "SixTix",
      };

    case "france":
      return {
        url: devUrl || "https://dev-app.fanceetickets.com/",
        theme: "france",
        title: "France",
      }

    default:
      localStorage.setItem("region", "sixtix"); // dev feature

      return {
        url: devUrl || "https://dev-app.fanceetickets.com/",
        theme: "sixtix",
        title: "SixTix",
      }
  }
}