import React, { Component } from "react";
import { connect } from "react-redux";
import Canvas from "../../../../../components/Canvas/Canvas";
import { canvasInit, canvasSetCenter } from "../../../../../components/Canvas/helpers";
import CanvasActions from "../../../../../components/CanvasActions/CanvasActions";
import CanvasSidebar from "../../../../../components/CanvasSidebar/CanvasSidebar";
import CanvasZoom from "../../../../../components/CanvasZoom/CanvasZoom";
import CavasSizesMenu from "../../../../../components/CavasSizesMenu/CavasSizesMenu";
import CheckBox from "../../../../../components/UI/Checkbox/CheckBox";
import { DEFAULT_CANVAS_FOOTER_IMAGE_OBJECT } from "../../../../../constants/canvas";
import { generateUniqueId } from "../../../../../components/Canvas/helpers";
import {
  setScrollPosition,
  setZoom,
  resetTemplatesState,
  setCurrentTemplate,
  deleteCanvasObject,
  appendFooterImageObject,
  getTemplate,
  getTemplates,
} from "../../../../../store/actions/canvasActions";

import "./EventPDF.sass";

class TemplateEditor extends Component {
  canvasRef = React.createRef();
  contextRef = React.createRef();
  containerRef = React.createRef();
  state = {
    windowSize: { width: 0, height: 0 },
  };

  setRef = (canvas) => {
    if (canvas) {
      this.canvasRef.current = canvas;

      const context = canvas.getContext("2d", {
        willReadFrequently: true,
      });

      if (!context) return;

      this.contextRef.current = context;

      canvasInit({
        canvas,
        context,
        canvasWidth: this.props.canvasWorkingSize.width,
        canvasHeight: this.props.canvasWorkingSize.height,
      });
    }
  };

  toggleTemplateFooter = async (isChecked) => {
    const {
      currentTemplate,
      setCurrentTemplate,
      canvasObjects,
      deleteCanvasObject,
      appendFooterImageObject
    } = this.props;

    const footerObject = canvasObjects.find(
      (o) => o.type === DEFAULT_CANVAS_FOOTER_IMAGE_OBJECT.type
    );

    if (footerObject) {
      if (!isChecked) {
        deleteCanvasObject(footerObject.id)
      } else {
        console.log('sds')
      }
    } else {
      if (isChecked) {
        appendFooterImageObject({
          ...DEFAULT_CANVAS_FOOTER_IMAGE_OBJECT,
          y: currentTemplate.height - DEFAULT_CANVAS_FOOTER_IMAGE_OBJECT.height,
          id: generateUniqueId(),
        })
      }
    };

    setCurrentTemplate({ ...currentTemplate, hasFooter: isChecked });
  }

  componentDidMount() {
    const html = document.querySelector("html");

    if (this.containerRef.current) {
      const containerRef = this.containerRef.current;

      const windowSize = {
        width: containerRef.clientWidth + 60,
        height: containerRef.clientHeight,
      };

      this.setState({
        windowSize,
      });

      const { canvasWorkingSize, setScrollPosition, setZoom } = this.props;

      canvasSetCenter({
        canvas: this.canvasRef.current,
        context: this.contextRef.current,
        canvasWorkingSize,
        setScrollPosition,
        setZoom,
        windowSize,
      });
    }

    if (html) {
      html.style.overflow = "hidden";
    }
  };

  componentWillUnmount() {
    const html = document.querySelector("html");

    if (html) {
      html.style.overflow = "auto";
    };

    this.props.resetTemplatesState();
  }

  render() {
    const { hasFooter } = this.props.currentTemplate;

    return (
      <div className="new-event__pdf pdf">
        <div className="pdf__canvas-wrapper">
          <Canvas
            setRef={this.setRef}
            containerRef={this.containerRef}
            windowSize={this.state.windowSize}
            contextRef={this.contextRef}
            canvasRef={this.canvasRef}
          />
          <div className="pdf__save">
            <CheckBox
              name="hasFooter"
              id="hasFooter"
              checked={hasFooter}
              onChange={(e) => {
                this.toggleTemplateFooter(e.target.checked)
              }}
            >
              Footer
            </CheckBox>
            <CanvasZoom />
            <CanvasActions />
          </div>
        </div>
        <CavasSizesMenu
          canvasRef={this.canvasRef}
          contextRef={this.contextRef}
          windowSize={this.state.windowSize}
        />
        <CanvasSidebar
          contextRef={this.contextRef}
          canvasRef={this.canvasRef}
          windowSize={this.state.windowSize}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ canvas, events, auth: { user } }) => ({
  canvasWorkingSize: canvas.canvasWorkingSize,
  currentTemplate: canvas.currentTemplate,
  zoom: canvas.zoom,
  canvasObjects: canvas.canvasObjects,
  templates: canvas.templates,
  currentEvent: events.currentEvent,
  user
});

const mapDispatchToProps = (dispatch) => ({
  setScrollPosition: (scrollPosition) =>
    dispatch(setScrollPosition(scrollPosition)),
  setZoom: (zoom) => dispatch(setZoom(zoom)),
  resetTemplatesState: () => dispatch(resetTemplatesState()),
  setCurrentTemplate: (template) => dispatch(setCurrentTemplate(template)),
  deleteCanvasObject: (objId) => dispatch(deleteCanvasObject(objId)),
  appendFooterImageObject: (obj) => dispatch(appendFooterImageObject(obj)),
  getTemplates: (eventId) => dispatch(getTemplates(eventId)),
  getTemplate: (templateId, token) => dispatch(getTemplate(templateId, token)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TemplateEditor);
