import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import moment from "moment";
import queryString from "query-string";
import Select from "react-select";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import CheckBox from "../../../components/UI/Checkbox/CheckBox";
import TermsAndConditionsModal from "../TermsAndConditionsModal/TermsAndConditionsModal";
import { getEvents } from "../../../store/actions/eventsActions";

import ReactTable from "react-table";

import axios from "../../../axios-instance";
import Upload from "../../Upload/Upload";
import Container from "../../../components/UI/Container/Container";
import Membership2025Adult from "../../../images/membership2025-adult.png";
import Membership2025Child from "../../../images/membership2025-child.png";
import { IconButton } from "../../../components/UI/IconButton/IconButton";
import Page404 from "../../../components/Page404/Page404";
import NewLoader from "../../../components/NewLoader/NewLoader";
import PleaseWait from "../../../images/please-wait-season-pass-modal.svg";

import "./SeasonPassPersonalInfo.sass";
import ThanksForYourPurchase from "../../../images/thanks-for-your-purchase.svg";
import Icon from "../../../components/UI/Icon/Icon";
import { showErrorModal, showInfoModal } from "../../../modals";
import { paymentMethodOptions } from "../constants";
import { mapSeat } from "../../OpenSell/OpenSell";
import { TicketsPicker } from "../../OpenSell/TicketsPicker";
import { getCurrencieLabel } from "../../../constants/currencies";
import { createMerchantSin } from "../helpers";
import { getTokenFormPaymentApi } from "../../../helpers/getTokenFormPaymentApi";
import { days, month, years } from "../constants";
import { getCurrentLanguageTexts, setLangCode } from "../../../constants/seasonPassPersonalInfoLangs";
import { MembershipCardContainer } from "./MembershipCard";

import { editMembeshipCardInfo, getMemberCards } from "../../../store/actions/memberActions";
import { BuyAllSeasonPassesModal } from "./Modals/BuyAllSeasonPassesModal";
import { MemberShipTickets } from "./MembershipTickets";
import ls from 'localstorage-slim';

const MySwal = withReactContent(Swal);

const transactionData = {
  resURL: `${process.env.API_URL}/Payment/Webhook/Kopa`,
  sessionValidity: "2023-08-28T14:26:53.000Z",
  redirectURL: window.location.href + "&",
  lang: "en",
  merchantName: "SIXTIX d.o.o",
  iFDirective: process.env.REACT_APP_ALLOWED_URL,
  signedKeys:
    "orderId,paymentAmount,currencyCode,sessionValidity,resURL,redirectURL,lang,merchantName,iFDirective,accessToken",
};

export const SeasonPassPersonalInfo = ({ dispatch, membershipList, getMemberCards, editMembeshipCardInfo, ...params }) => {
  const allowMembershipSell = true; // always allow

  const [isLoadingState, setIsLoadingState] = useState(true);
  const [isMemberFound, setIsMemberFound] = useState(false);
  const [isTermsChecked, setIsTermsChecked] = useState(false);

  const formRef = useRef(null);
  const formForSeasonRef = useRef(null);

  const [orderId, setOrderId] = useState("");
  const [paymentAmount, setPaymentAmount] = useState(0);
  const [orderSeasonId, setOrderSeasonId] = useState("");
  const [paymentSeasonAmount, setPaymentSeasonAmount] = useState(0);
  const [currencyCode, setCurrencyCode] = useState("RSD");
  const [tickets, setTickets] = useState([]);
  const [eventData, setEventData] = useState(null);
  const [seasonEventData, setSeasonEventData] = useState(null);

  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [streetAndHouseNumber, setStreetAndHouseNumber] = useState("");
  const [city, setCity] = useState("");
  const [postalCode, setPostalCode] = useState("");

  const [accessTokenFromPaymentApi, setAccessTokenTokenFromPaymentApi] =
    useState("");

  const [points, setPoints] = useState("");
  const [seasonPassList, setSeasonPassList] = useState([]);
  const [seasonPassListForDelete, setSeasonPassListForDelete] = useState([]);
  const [filesList, setFilesList] = useState([]);
  const [resaleRequestsList, setResaleRequestsList] = useState([]);
  const [canSave, setCanSave] = useState(true);
  const [isPendingToApprove, setIsPendingToApprove] = useState(false);
  const [isApproved, setIsApproved] = useState(false);
  const [birthday, setBirthday] = useState(new Date());

  const [pickerData, setPickerData] = useState({
    year: null,
    month: null,
    days: null,
  });
  const [defaultLang, setDefaultLang] = useState("en");

  const txt = getCurrentLanguageTexts();

  const handleLang = (lang) => {
    const acceptedLangs = ['en', 'rs'];
    if (!lang || !acceptedLangs.some((key) => key == lang)) {
      setLangCode('rs');
      setDefaultLang("rs");
      return;
    }

    setLangCode(lang);
    setDefaultLang(lang);
  };

  const pageAction = queryString.parse(params.location.search).action;

  const token = queryString.parse(params.location.search).token;

  ls.set('member-access-token', token);

  const authResult = queryString.parse(params.location.search)["?authResult"];

  const changeInputFirstnameHandler = (e) => {
    setFirstname(e.target.value);
  };

  const changeInputLastnameHandler = (e) => {
    setLastname(e.target.value);
  };

  const changeInputEmailHandler = (e) => {
    setEmail(e.target.value);
  };

  const changeInputPhoneHandler = (e) => {
    setPhone(e.target.value);
  };

  const changeInputStreetAndHouseNumberHandler = (e) => {
    setStreetAndHouseNumber(e.target.value);
  };

  const changeInputCityHandler = (e) => {
    setCity(e.target.value);
  };

  const changeInputPostalCodeHandler = (e) => {
    setPostalCode(e.target.value);
  };

  const showTermsAndConditionsModal = (ev) => {
    ev.preventDefault();
    MySwal.fire({
      html: <TermsAndConditionsModal />,
      width: "64em",
      customClass: "seasonpass__modal",
    });
  };

  const openBuyAllModal = async () => {
    const seatsToBuy = membershipList.filter(t => !t.hasSeasonPass && !!t.seat)

    const { data: eventData } = await axios.get(`EventPublicInfo/BySlug/${process.env.REACT_APP_NEW_SEASON_EVENT_SLUG}`);
    const { data } = await axios.post(`TicketTemplate/${process.env.REACT_APP_NEW_SEASON_EVENT_ID}/by-seats`,
      seatsToBuy.map(c =>
        c.area + (!!c.side ? `-${c.side}` : '') + `-${c.row}-${c.seat}`)
    )

    MySwal.fire({
      title: txt.BuyAllSeasonPassesModalTxt['title'],
      html: (
        <BuyAllSeasonPassesModal
          membershipCards={seatsToBuy}
          availablePoints={0} // disabled for invoices
          onConfirmBuySeasonPass={onConfirmBuySeasonPass}
          event={eventData}
          selectedTicketTemplates={data}
        />
      ),
      showConfirmButton: false,
      customClass: "public-user-modal",
    });
  };

  const checkActionButtonDisabled = () => {
    if (pageAction == 'edit')
      return false;

    let res = !((birthday > moment("1950-01-01")) && firstname && lastname && phone && streetAndHouseNumber && city && postalCode && true)

    return res;
  };

  const handleChangeDate = (type, date) => {
    let newPickerData = { ...pickerData, [type]: date };

    setPickerData(newPickerData);

    const isNoEmpty = Object.keys(newPickerData).every(
      (key) => typeof newPickerData[key] === "number"
    );

    const birthday = moment()
      .year(newPickerData.year)
      .month(newPickerData.month)
      .set("D", newPickerData.days)
      .toDate();

    setBirthday(birthday);
  };

  const showPleaseWaitModal = () => {
    MySwal.fire({
      imageUrl: PleaseWait,
      title: waitModalTxt.title,
      text: waitModalTxt.description,
      allowEscapeKey: false,
      customClass: "partizan-modal seasonpass__modal partizan-info-modal",
      allowOutsideClick: false,
      allowEnterKey: false,
      showConfirmButton: false,
    });
  };

  const setPrice = (ticks) => {
    setPaymentAmount(
      ticks.reduce((accumulator, ticket) => {
        return accumulator + ticket.price * ticket.count;
      }, 0)
    );
  };

  const onObjectSelected = (obj) => {
    let ticks = [...tickets, obj];

    let findedTicket = tickets.find(
      ({ ticketTemplateId }) => ticketTemplateId === obj.ticketTemplateId
    );

    if (findedTicket) {
      ticks = tickets.map((ticket) =>
        ticket.ticketTemplateId === obj.ticketTemplateId ? obj : ticket
      );
    }

    setPrice(ticks);
    setTickets(ticks);
  };

  const onObjectDeselected = (obj) => {
    const ticks = tickets.filter(
      (item) => item.ticketTemplateId !== obj.ticketTemplateId
    );
    setPrice(ticks);
    setTickets(ticks);
  };

  const getEventData = async () => {
    const eventSlug = process.env.REACT_APP_MEMBER_EVENT_SLUG;
    const { data } = await axios.get(`/EventPublicInfo/BySlug/${eventSlug}`);
    const seasonData = await axios.get(`/EventPublicInfo/BySlug/${process.env.REACT_APP_NEW_SEASON_EVENT_SLUG}`);
    const paymentApiRes = await getTokenFormPaymentApi({
      merchantId: data.merchantId,
    });
    if (paymentApiRes) {
      setAccessTokenTokenFromPaymentApi(paymentApiRes.data.access_token);
    }
    setCurrencyCode(
      getCurrencieLabel(data.currencyId)
    );
    if (pageAction === 'selling') {
      seasonData.data.feeRate = 0;
      data.feeRate = 0;
    }
    setEventData(data);
    setSeasonEventData(seasonData.data);
  };

  const createOrder = async () => {
    let seats = tickets.reduce(mapSeat, []);

    const body = {
      transaction: {
        fullName: firstname + " " + lastname,
        physicalAddress: streetAndHouseNumber,
        city,
        postalCode,
        phoneNumber: phone,
        buyerEmail: email,
        paymentMethod: paymentMethodOptions[0].value,
        currency: transactionData.currencyCode,
        amount: paymentAmount,
        seats,
      },
    };

    const { data: response } = await axios.post("Transaction/createForSell", body);

    setOrderId(response.transactionId);
  };

  const createNfcOrderForTheSeatOrder = async (mc) => {
    let paymentType = 2;
    let isOfflineSelling = false;
    let paymentAmount = 3000;
    let user = JSON.parse(localStorage.getItem("user"));
    if (pageAction === 'selling' && user) {
      let result = await getEvents(user.token, false, '', 0, 0);
      if (result) {
        isOfflineSelling = true;
      }
    }

    if (!isOfflineSelling) {
      return;
    }

    const body = {
      transaction: {
        fullName: mc.customerName,
        physicalAddress: streetAndHouseNumber,
        city,
        postalCode,
        phoneNumber: phone,
        buyerEmail: email,
        paymentMethod: paymentMethodOptions[0].value,
        currency: transactionData.currencyCode,
        amount: paymentAmount,
        couponCode: ``,
        paymentType: paymentType,
        seats: [
          {
            "ticketName": "NFC card",
            "area": mc.area,
            "row": mc.row,
            "seat": mc.seat,
            "side": mc.side,
            "ticketTemplateId": process.env.REACT_APP_NEW_SEASON_NFC_CARD_TICKET_TEMPLATE_ID,
            "amount": 3000,
            "discount": 0,
            "fixedDiscount": 0
          }
        ],
      },
    };

    const { data: response } = await axios.post("Transaction/createForSell", body);

    if (isOfflineSelling) {
      axios
        .post(
          `/ManageTransactions/ApproveTransaction?transactionId=${response.transactionId}&makeFiscalization=false`,
          null,
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
              Accept: "text/plain",
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            //toast.success("Transaction successfully approved");
            return '';
          }

          throw new Error(response.data.errorMessage);
        })
        .catch((err) => {
          //toast.error("Transaction not approved");
        });
    }
    return paymentType == 1 ? response.transactionId : '';
  }

  const createSeasonPassOrder = async (seats, fullName, paymentAmount, pointToDiscount, { companyName, companyCity, companyAddress, companyVat, companyCountry }) => {
    const onlinePayment = !companyName;
    let paymentType = onlinePayment ? 1 : 6;
    let isOfflineSelling = false;
    let user = JSON.parse(localStorage.getItem("user"));
    if (pageAction === 'selling' && user) {
      let result = await getEvents(user.token, false, '', 0, 0);
      if (result) {
        isOfflineSelling = true;
        paymentType = 2;
      }
    }

    const body = {
      transaction: {
        fullName,
        physicalAddress: streetAndHouseNumber,
        city,
        postalCode,
        phoneNumber: phone,
        buyerEmail: email,
        paymentMethod: paymentMethodOptions[0].value,
        currency: transactionData.currencyCode,
        amount: paymentAmount,
        companyName,
        companyCity,
        companyAddress,
        companyVatNumber: companyVat,
        couponCode: `RESALE_POINTS_${pointToDiscount}`,
        paymentType: paymentType,
        seats,
        companyCountry,
      },
    };

    const { data: response } = await axios.post("Transaction/createForSell", body);
    setPaymentSeasonAmount(paymentAmount);

    if (isOfflineSelling) {
      axios
        .post(
          `/ManageTransactions/ApproveTransaction?transactionId=${response.transactionId}&makeFiscalization=false`,
          null,
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
              Accept: "text/plain",
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            //toast.success("Transaction successfully approved");
            return '';
          }

          throw new Error(response.data.errorMessage);
        })
        .catch((err) => {
          //toast.error("Transaction not approved");
        });
    }
    return paymentType == 1 ? response.transactionId : '';
  }

  const showBuy = () => {
    MySwal.fire({
      title: buyMembershipTxt.buyTitle,
      text: buyMembershipTxt.buyDescription,
      confirmButtonText: buyMembershipTxt.buyBtn,
      cancelButtonText: buyMembershipTxt.cancelBtn,
      showCancelButton: true,
      reverseButtons: true,
      customClass: "partizan-modal delete-user-modal",
    }).then(({ value }) => {
      if (value) {
        handleBuy();
      }
    });
  };

  const handleBuy = async () => {
    try {
      showPleaseWaitModal();
      await createOrder();
      formRef.current.submit();
    } catch (e) {
      console.log(e);
      showErrorModal({
        text: errorModalTxt.description,
        customClass:
          "seasonpass__modal_info seasonpass__modal partizan-info-modal",
      });
    }
  };

  const onConfirmBuyNfc = async (mc) => {

    try {
      showPleaseWaitModal();
      const id = await createNfcOrderForTheSeatOrder(mc);

      if (id) {
        setOrderSeasonId(id);

        setTimeout(() => formForSeasonRef.current.submit(), 500);
      }
      else {
        MySwal.close();
        toast.success("NFC added successfully")
      }

    } catch (e) {
      console.log(e);
      showErrorModal({
        text: errorModalTxt.description,
        customClass:
          "seasonpass__modal_info seasonpass__modal partizan-info-modal",
      });
    }
  }

  const onConfirmBuySeasonPass = async (seats, fullName, paymentAmount, pointToDiscount, { companyName = '', companyCity = '', companyAddress = '', companyVat = '', companyCountry = '' }) => {

    try {
      showPleaseWaitModal();
      const id = await createSeasonPassOrder(seats, fullName, paymentAmount, pointToDiscount, { companyName, companyCity, companyAddress, companyVat, companyCountry });

      if (id) {
        setOrderSeasonId(id);

        setTimeout(() => formForSeasonRef.current.submit(), 500);
      }
      else {
        MySwal.close();
        toast.success("Invoice sent successfully")
      }

    } catch (e) {
      console.log(e);
      showErrorModal({
        text: errorModalTxt.description,
        customClass:
          "seasonpass__modal_info seasonpass__modal partizan-info-modal",
      });
    }
  }

  const deleteFile = (file, type) => {
    MySwal.fire({
      title: deleteFileTxt.title,
      text: deleteFileTxt.description,
      confirmButtonText: deleteFileTxt.confirmBtn,
      cancelButtonText: deleteFileTxt.cancelBtn,
      showCancelButton: true,
      reverseButtons: true,
      customClass: "partizan-modal delete-user-modal",
    }).then(({ value }) => {
      if (value) {
        if (type === "local") {
          setFilesList((currFiles) => currFiles.filter((f) => f !== file));
        } else {
          setSeasonPassList((currSeasons) =>
            currSeasons.filter((season) => season.id !== file.id)
          );

          setSeasonPassListForDelete((currSeasons) => [...currSeasons, file]);
        }
      }
    });
  };

  const onAddFileHandler = (files, clearAllSelected) => {
    if (files.length > 0) {
      const fileReader = new FileReader();

      fileReader.onloadend = () => {
        setFilesList((currFiles) => [
          ...currFiles,
          { file: files[0], url: fileReader.result },
        ]);

        clearAllSelected();
      };

      fileReader.readAsDataURL(files[0]);
    }
  };

  const loadSeasonPassMember = async () => {
    await axios
      .get(`/SeasonPassMember?accessToken=${token}`)
      .then((response) => {
        if (response.status === 200 && response.data) {
          setFirstname(response.data.firstName);
          setLastname(response.data.lastName);
          setEmail(response.data.email);
          handleOrderStatuses({ email: response.data.email });
          setPhone(response.data.phoneNumber);
          setStreetAndHouseNumber(response.data.physicalAddress);
          setCity(response.data.city);
          setPostalCode(response.data.postalCode);
          setResaleRequestsList(response.data.resaleRequests);
          setPoints(response.data.points);
          setIsPendingToApprove(response.data.status === 2);

          setPickerData({
            year: moment(response.data.dateOfBirth).year(),
            month: moment(response.data.dateOfBirth).month(),
            days: +moment(response.data.dateOfBirth).format("D"),
          });
          setBirthday(response.data.dateOfBirth);
          //handleLang(response.data.lang);
          if (response.data.status === 3) {
            getEventData();
            setIsApproved(true);
          }
          localStorage.setItem('user_' + response.data.email.toLowerCase().trim(), token);
          setIsMemberFound(true);
          setIsLoadingState(false);

          if (pageAction == 'edit') {
            setIsPendingToApprove(false);
            setIsApproved(false);
          }
          return;
        }

        throw new Error(response.data.errorMessage);
      })
      .catch((err) => {
        setIsLoadingState(false);
        setIsMemberFound(false);
      });

    await axios
      .get(`/SeasonPassMember/passes?accessToken=${token}`)
      .then((response) => {
        if (response.status === 200 && response.data) {
          const list = response.data.filter(
            (season) => season.requestedToUpdate
          );

          setSeasonPassList(list);
          setIsMemberFound(true);
          setIsLoadingState(false);
          return;
        }

        throw new Error(response.data.errorMessage);
      })
      .catch((err) => {
        setIsLoadingState(false);
        setIsMemberFound(false);
      });
  };

  useEffect(() => {
    if (token) {
      getMemberCards(token, process.env.REACT_APP_MEMBER_EVENT_ID);
    }
  }, [token]);

  const { confirmSaveChanges, handleOrderStatusesTxt, pointsTable, buyMembershipTxt, waitModalTxt, errorModalTxt, deleteFileTxt, dataFormTxt, SeasonPassListTxt } = getCurrentLanguageTexts();

  const showConfirmSaveChangesModal = () => {
    MySwal.fire({
      title: confirmSaveChanges.title,
      text: confirmSaveChanges.text,
      confirmButtonText: confirmSaveChanges.btnConfirm,
      cancelButtonText: confirmSaveChanges.btnCancel,
      showCancelButton: true,
      reverseButtons: true,
      customClass: "partizan-modal delete-user-modal",
    }).then(({ value }) => {
      if (value) {
        const data = {
          firstName: firstname,
          lastName: lastname,
          phoneNumber: phone,
          physicalAddress: streetAndHouseNumber,
          city: city,
          postalCode: postalCode,
          dateOfBirth: birthday
        };
        const isFinal = pageAction != 'edit';
        axios
          .put(`/SeasonPassMember?accessToken=${token}&isFinal=${isFinal}`, data)
          .then((response) => {
            if (response.status === 200 && response.data) {

              if (response.data.result == 2) // Pending to approve
              {
                setIsPendingToApprove(true);
                setIsApproved(false);
              }
              else {// Pending approved
                setIsPendingToApprove(false);
                setIsApproved(true);
              }
              return;
            }

            throw new Error(response.data.errorMessage);
          })
          .catch((err) => {
            toast.error("Something went wrong");
          });

        filesList.forEach((value, index, array) => {
          const data = new FormData();
          data.append("file", value.file);

          axios
            .post(
              `/SeasonPassMember/attach-season-pass-photo?accessToken=${token}`,
              data
            )
            .then((response) => {
              if (response.status === 200 && response.data) {
                return;
              }

              throw new Error(response.data.errorMessage);
            })
            .catch((err) => {
              toast.error("Something went wrong");
            });
        });

        seasonPassListForDelete.forEach((season, index, array) => {
          axios
            .delete(
              `/SeasonPassMember/detach-season-pass-photo?seasonPassId=${season.id}&accessToken=${token}`
            )
            .then((response) => {
              if (response.status === 200 && response.data) {
                return;
              }

              throw new Error(response.data.errorMessage);
            })
            .catch((err) => {
              toast.error("Something went wrong");
            });
        });
      }
    });
  };

  const removePayStatus = () => {
    params.history.replace(`${params.match.url}?token=${token}&lang=${defaultLang}`);
  };

  const handleOrderStatuses = (data) => {
    switch (authResult) {
      case "CANCELLED":
        break;
      case "AUTHORISED":
        showInfoModal({
          image: ThanksForYourPurchase,
          title: handleOrderStatusesTxt.thanksForYourPurchase,
          allowEscapeKey: true,
          allowOutsideClick: true,
          allowEnterKey: true,
          onConfirm: removePayStatus,
          text: <>{handleOrderStatusesTxt.thanksText1}<strong>{data.email}</strong>{handleOrderStatusesTxt.thanksText2}</>,
          // additionalText: `(Payment Status: ${
          //   data.additionalPaymentStatus
          // }, Payment Date: ${moment(data.paymentDate).format(
          //   "DD MMMM YYYY HH:mm"
          // )}, Transaction Id: ${data.transactionId})`,
          customClass:
            "seasonpass__modal_info seasonpass__modal partizan-info-modal",
        });
        break;
      case "REFUSED":
        showErrorModal({
          text: handleOrderStatusesTxt.refusedText,
          customClass:
            "seasonpass__modal_info seasonpass__modal partizan-info-modal",
          // additionalText: `(Payment Status: ${
          //   data.additionalPaymentStatus
          // }, Payment Date: ${moment(data.paymentDate).format(
          //   "DD MMMM YYYY HH:mm"
          // )}, Transaction Id: ${data.transactionId})`,
        });
        break;
      default:
        break;
    }
  };

  const getTicketTemplates = () => {
    let ticks = [];

    ticks = eventData.ticketTemplates.map((ticket) => {
      if (ticket.name.toLowerCase().includes("extend")) {
        const numberOfExtendTickens = tickets.reduce((num, item) => {
          if (
            item.name.toLowerCase().includes("extend") &&
            ticket.ticketTemplateId !== item.ticketTemplateId
          ) {
            return num + item.count;
          }
          return num;
        }, 0);

        if (numberOfExtendTickens) {
          return {
            ...ticket,
            allowedToSell:
              seasonPassList.length - numberOfExtendTickens > 0
                ? seasonPassList.length - numberOfExtendTickens
                : 0,
          };
        }

        return { ...ticket, allowedToSell: seasonPassList.length };
      }
      return ticket;
    });
    let count = 0;
    for (let j = 0; j < membershipList.length; j++) {
      if (membershipList[j].ticketName.toLowerCase().includes("extend")) {
        ++count;
      }
    }
    for (let i = 0; i < ticks.length; i++) {
      if (
        count >= seasonPassList.length &&
        ticks[i].name.toLowerCase().includes("extend")
      ) {
        delete ticks[i];
      }
    }
    ticks.forEach((t, i) => {
      ticks[i].imgUrl = ticks[i].name.toLowerCase().includes("child") ? Membership2025Child : Membership2025Adult;
    });

    return ticks;
  };

  useEffect(() => {
    if (Array.isArray(authResult)) {
      params.history.replace(`${params.match.url}?token=${token}&lang=${defaultLang}&?authResult=${authResult[0]}`);

      return;
    }
    handleLang(queryString.parse(params.location.search).lang);
    loadSeasonPassMember();
  }, [authResult]);

  if (isLoadingState) {
    return <NewLoader />;
  }

  if (!isMemberFound) {
    return <Page404 />;
  }

  const columns = [
    {
      // Header: pointsTable.eventNameHeader,
      // accessor: "eventName",
      width: 300,
      Footer: () => (
        <span className="seasonpass-pers-info__points-total">
          {pointsTable.totalPoints} : {points}
        </span>
      ),
    },
    // {
    //   Header: pointsTable.complexSeatHeader,
    //   width: 200,
    //   accessor: "complexSeat",
    // },
    // {
    //   Header: pointsTable.pointsHeader,
    //   accessor: "points",
    // },
  ];

  const allowInvoices = membershipList.filter(m => !m.hasSeasonPass && m.hasDefaultSeat).length > 0;

  return (
    <Container className="seasonpass seasonpass-pers-info">

      <div className="seasonpass-pers-info__logo seasonpass-pers-info__header-logo-wrapper">
        {/* <img src={PartizanOpenSellingLogo} alt="Logo" />
        <h2>SixTix</h2> */}
      </div>
      <div className="seasonpass-pers-info__main-block">
        {isPendingToApprove ? (
          <div className="seasonpass-pers-info__notification">
            <Icon name="clock-white" />
            <h4>{waitModalTxt.waitingForReview}</h4>
          </div>
        ) : null}
        <h2 className="first-title">{dataFormTxt.personalInfoTitle}</h2>
        <div className="seasonpass-pers-info__row">
          <div className="seasonpass-pers-info__column">
            <div className="seasonpass-pers-info__block">
              <label>{dataFormTxt.firstName}</label>
              <input
                type="text"
                name="firstname"
                disabled={isPendingToApprove || isApproved}
                placeholder=""
                value={firstname}
                onChange={changeInputFirstnameHandler}
              />
            </div>
          </div>

          <div className="seasonpass-pers-info__column">
            <div className="seasonpass-pers-info__block">
              <label>{dataFormTxt.lastName}</label>
              <input
                type="text"
                name="lastname"
                disabled={isPendingToApprove || isApproved}
                placeholder=""
                value={lastname}
                onChange={changeInputLastnameHandler}
              />
            </div>
          </div>
        </div>
        <div className="seasonpass-pers-info__row">
          <div className="seasonpass-pers-info__column">
            <div className="seasonpass-pers-info__block">
              <label>{dataFormTxt.email}</label>
              <input
                type="text"
                name="email"
                className="email-disable"
                placeholder=""
                value={email}
                onChange={changeInputEmailHandler}
                disabled
              />
            </div>
          </div>
          <div className="seasonpass-pers-info__column">
            <div className="seasonpass-pers-info__block">
              <label>{dataFormTxt.phone}</label>
              <input
                type="text"
                name="phone"
                placeholder=""
                value={phone}
                disabled={isPendingToApprove || isApproved}
                onChange={changeInputPhoneHandler}
              />
            </div>
          </div>
        </div>
        <div className="seasonpass-pers-info__row">
          <div className="seasonpass-pers-info__column">
            <div className="seasonpass-pers-info__block">
              <label>{dataFormTxt.streetAndHouse}</label>
              <input
                type="text"
                name="street-and-house-number"
                placeholder=""
                value={streetAndHouseNumber}
                disabled={isPendingToApprove || isApproved}
                onChange={changeInputStreetAndHouseNumberHandler}
              />
            </div>
          </div>
          <div className="seasonpass-pers-info__column">
            <div className="seasonpass-pers-info__block">
              <label>{dataFormTxt.city}</label>
              <input
                type="text"
                name="city"
                placeholder=""
                value={city}
                disabled={isPendingToApprove || isApproved}
                onChange={changeInputCityHandler}
              />
            </div>
          </div>
          <div className="seasonpass-pers-info__column">
            <div className="seasonpass-pers-info__block">
              <label>{dataFormTxt.postalCode}</label>
              <input
                type="text"
                name="postal-code"
                placeholder=""
                value={postalCode}
                disabled={isPendingToApprove || isApproved}
                onChange={changeInputPostalCodeHandler}
              />
            </div>
          </div>
        </div>
        {!isPendingToApprove && !isApproved && (
          <div className="seasonpass-pers-info__row">
            <div className="seasonpass-pers-info__column">
              <div className="seasonpass-pers-info__block">
                <label>{dataFormTxt.year}</label>
                <Select
                  name="year"
                  options={years}
                  placeholder=""
                  value={years.find(({ value }) => pickerData.year === value)}
                  onChange={(value) => handleChangeDate("year", value.value)}
                  disabled={isPendingToApprove || isApproved}
                  classNamePrefix="partizan-open-selling__select"
                  className="partizan-open-selling__select"
                />
              </div>
            </div>
            <div className="seasonpass-pers-info__column">
              <div className="seasonpass-pers-info__block">
                <label>{dataFormTxt.month}</label>
                <Select
                  name="month"
                  options={month}
                  placeholder=""
                  value={month.find(({ value }) => pickerData.month === value)}
                  onChange={(value) => handleChangeDate("month", value.value)}
                  disabled={isPendingToApprove || isApproved}
                  classNamePrefix="partizan-open-selling__select"
                  className="partizan-open-selling__select"
                />
              </div>
            </div>
            <div className="seasonpass-pers-info__column">
              <div className="seasonpass-pers-info__block">
                <label>{dataFormTxt.day}</label>
                <Select
                  name="day"
                  options={days}
                  placeholder=""
                  value={days.find(({ value }) => pickerData.days === value)}
                  onChange={(value) => handleChangeDate("days", value.value)}
                  disabled={isPendingToApprove || isApproved}
                  classNamePrefix="partizan-open-selling__select"
                  className="partizan-open-selling__select"
                />
              </div>
            </div>
          </div>
        )}
        {!isApproved && allowMembershipSell && (
          <>
            <h2 className="first-title">{SeasonPassListTxt.spListTitle}</h2>
            <div className="seasonpass-pers-info__list">
              {seasonPassList.map((season) => (
                <div className="upload-img">
                  <img src={season.confirmationPhotoUrl} />
                  {!isPendingToApprove && (
                    <IconButton
                      disabled={!canSave}
                      iconName="trash"
                      className="btn-red IconButton"
                      onClick={() => deleteFile(season, "remote")}
                    />
                  )}
                </div>
              ))}
              {filesList.map((file) => (
                <div className="upload-img">
                  <img src={file.url} />
                  {!isPendingToApprove && (
                    <IconButton
                      disabled={!canSave}
                      iconName="trash"
                      className="btn-red IconButton"
                      onClick={() => deleteFile(file, "local")}
                    />
                  )}
                </div>
              ))}
              {!isPendingToApprove && (
                <>
                  <Upload
                    accept="image/png, image/jpeg, image/*"
                    onButtonEnable={onAddFileHandler}
                  />
                </>
              )}
            </div>
          </>
        )}

        {/* MEMBERSHIP FORM */}
        <form
          ref={formRef}
          action={process.env.REACT_APP_PAYMENT_LINK}
          method="post"
        >
          {Object.keys(transactionData).map((key) => (
            <input
              name={key}
              key={key}
              type="hidden"
              value={transactionData[key]}
            />
          ))}
          <input
            name={"orderId"}
            key={"orderId"}
            type="hidden"
            value={orderId}
          />
          <input
            name={"paymentAmount"}
            key={"paymentAmount"}
            type="hidden"
            value={paymentAmount}
          />

          <input
            name={"currencyCode"}
            key={"currencyCode"}
            type="hidden"
            value={currencyCode}
          />
          <input
            name={"accessToken"}
            key={"accessToken"}
            type="hidden"
            value={accessTokenFromPaymentApi}
          />
          <input
            name={"merchantSig"}
            key={"merchantSig"}
            type="hidden"
            value={createMerchantSin(
              {
                ...transactionData,
                orderId,
                paymentAmount,
                currencyCode,
                accessToken: accessTokenFromPaymentApi,
              },
              transactionData.signedKeys
            )}
          />
        </form>

        {/* SEASON FORM */}
        <form ref={formForSeasonRef}
          action={process.env.REACT_APP_PAYMENT_LINK}
          method="post">
          {Object.keys(transactionData).map((key) => (
            <input
              name={key}
              key={key}
              type="hidden"
              value={transactionData[key]}
            />
          ))}

          <input
            name={"orderId"}
            key={"orderId"}
            type="hidden"
            value={orderSeasonId}
          />
          <input
            name={"paymentAmount"}
            key={"paymentAmount"}
            type="hidden"
            value={paymentSeasonAmount}
          />

          <input
            name={"currencyCode"}
            key={"currencyCode"}
            type="hidden"
            value={currencyCode}
          />
          <input
            name={"accessToken"}
            key={"accessToken"}
            type="hidden"
            value={accessTokenFromPaymentApi}
          />
          <input
            name={"merchantSig"}
            key={"merchantSig"}
            type="hidden"
            value={createMerchantSin(
              {
                ...transactionData,
                orderId: orderSeasonId,
                paymentAmount: paymentSeasonAmount,
                currencyCode,
                accessToken: accessTokenFromPaymentApi,
              },
              transactionData.signedKeys
            )}
          />

        </form>

        {isApproved && allowMembershipSell && eventData && (
          <>
            <h2 className="first-title">{buyMembershipTxt.buyMembershipsListTitle}</h2>
            <div className="seasonpass-pers-info__tick-list">
              <TicketsPicker
                canSelect={
                  tickets.reduce((num, item) => num + item.count, 0) <
                  eventData.maxSeatsToSelectPerTransaction
                }
                onObjectSelected={onObjectSelected}
                onObjectDeselected={onObjectDeselected}
                ticketTemplates={getTicketTemplates()}
                currency={currencyCode}
              />
            </div>
            <div className="seasonpass-pers-info__tick-total">
              <span className="">{buyMembershipTxt.total} </span>
              <span className="">
                {paymentAmount} {currencyCode}
              </span>
            </div>
            <CheckBox
              checked={isTermsChecked}
              id="terms-and-conditions-checkbox"
              name="terms-and-conditions-checkbox"
              onChange={() => setIsTermsChecked(!isTermsChecked)}
            >
              <span className="partizan-open-selling__text-medium-small">
                {buyMembershipTxt.iAgreeWith}
                <span
                  className="partizan-open-selling__terms-of-service-text" style={{ color: '#d7b36c' }}
                  onClick={showTermsAndConditionsModal}
                >
                  {buyMembershipTxt.termsOfService}
                </span>
              </span>
            </CheckBox>
            <button
              className="btn-primary save-changes"
              disabled={!paymentAmount || !isTermsChecked}
              onClick={showBuy}
            >
              {buyMembershipTxt.buy}
            </button>
          </>
        )}

        {isApproved && !!membershipList.length && (
          <>
            <h2 className="first-title">{SeasonPassListTxt.title}</h2>

            <MembershipCardContainer
              onSave={(payload) => editMembeshipCardInfo(token, payload)}
              membershipCards={membershipList}
              currencyLabel={currencyCode}
              onConfirmBuySeasonPass={onConfirmBuySeasonPass}
              availablePoints={pageAction === 'selling' ? 0 : points}
              eventInfo={seasonEventData}
              langCode={defaultLang}
              onConfirmBuyNfc={onConfirmBuyNfc}
              ableToBuyNFC={pageAction === 'selling'} />

            {allowInvoices &&
              <button className="btn-primary buy-all"
                onClick={openBuyAllModal}>{txt.SeasonPassListTxt['buyViaInvoice']}</button>
            }
          </>
        )}
        {!!membershipList.length
          && <>
            <h2 className="first-title">{`${txt.memberEventsList["Future events in"]} SEZONSKA 2024/2025`}</h2>

            <MemberShipTickets memberData={{
              fullName: firstname + ' ' + lastname,
              physicalAddress: streetAndHouseNumber,
              city: city,
              postalCode: postalCode,
              phoneNumber: phone,
              buyerEmail: email,
              membershipsCount: membershipList.length,
            }} />
          </>
        }

        {/* <ReactTable
          columns={columns}
          data={resaleRequestsList}
          showPagination={false}
          resizable={false}
          defaultPageSize={resaleRequestsList.length}
        /> */}

        <span className="seasonpass-pers-info__points-total">
          {pointsTable.totalPoints} : {points}
        </span>

        {!isPendingToApprove && !isApproved && (
          <button
            className="btn-primary save-changes"
            disabled={checkActionButtonDisabled()}
            onClick={showConfirmSaveChangesModal}
          >
            {dataFormTxt.saveChanges}
          </button>
        )}
      </div>
    </Container>
  );
};

const mapStateToProps = ({ member }) => ({
  membershipList: member.membershipList,
});
const mapDispatchToProps = (dispatch) => ({
  getMemberCards: (accessToken, eventId) =>
    dispatch(getMemberCards(accessToken, eventId)),
  editMembeshipCardInfo: (accessToken, ticket) =>
    dispatch(editMembeshipCardInfo(accessToken, ticket)),
  dispatch: (foo) => dispatch(foo),
});

export default connect(mapStateToProps, mapDispatchToProps)(SeasonPassPersonalInfo)
