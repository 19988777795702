import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import Container from "../../components/UI/Container/Container";
import PageTitle from "../../components/UI/PageTitle/PageTitle";
import axios from '../../axios-instance';
import { getHeaders } from "../../helpers/getHeaders";
import ls from 'localstorage-slim';

import styles from './BalancePage.module.sass';

const BalancePage = () => {
    const [state, setState] = useState({
        isLoaded: false,
        balance: 0,
        isPaynlAccount: false,
        name: 'Promoter'
    });

    const token = JSON.parse(localStorage.getItem("user")).token;

    useEffect(() => {
        (async () => {
            const balanceCache = ls.get('balance_cache')

            if (!balanceCache) {
                const data = await axios.get('/Promoter', {
                    headers: getHeaders(token),
                })
                
                setState(v => {
                    return {
                        ...v,
                        ...data.data,
                        isLoaded: true,
                    }
                });

                ls.set('balance_cache', {
                    ...state,
                    ...data.data,
                    isLoaded: true,
                }, { ttl: 60 })
            }
            else {
                setState(balanceCache)
            }
        })()
    }, []);

    return <Container className="transactions membership-managment">
        <PageTitle>Balance</PageTitle>

        <section>
            {state.isLoaded
                &&
                <h2 className={styles['balance-label']}>Balance for {state.name}: {(state.balance / 100.0).toFixed(2)} EUR</h2>
            }
        </section>

    </Container>
}

export default withRouter(BalancePage)