import React, { useState, useEffect, useMemo, useCallback } from "react";
import Select from "react-select";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import ReactTable from "react-table";
import withFixedColumns from "react-table-hoc-fixed-columns";
import ClipboardJS from "clipboard";
import moment from "moment";
import axios from "../../../../../axios-instance";
import { useShowActionsMenu } from "../../../../../components/UI/ActionsMenu/hooks/useShowActionsMenu";
import { useLazyLoadOnTableScroll } from "../../../../../hooks/useLazyLoadOnTableScroll";
import { ApproveTransactionModal } from "./Modals/ApproveTransactionModal";
import { EditTransactionModal } from "./Modals/EditTransactionModal";
import { RefundTicketsModal } from "./Modals/RefundTicketsModal";
import { getHeaders } from "../../../../../helpers/getHeaders";
import { loadFile } from "../../../../../helpers/loadFile";
import Icon from "../../../../../components/UI/Icon/Icon";
import { StatusLabel } from "../../../../../components/Transactions/StatusLabel/StatusLabel";
import { TransactionActions } from "./TransactionActions.js";
import { paymentStatuses } from "../../../../../constants/paymentStatuses";
import CheckBox from "../../../../../components/UI/Checkbox/CheckBox";
import NewLoader from "../../../../../components/NewLoader/NewLoader";
import PageTitle from "../../../../../components/UI/PageTitle/PageTitle";
import { approveTransaction, cancelOfferTransaction, clearTransactions, deleteSeatsTransaction, deleteTransaction, editTransaction, getTransactions } from "../../../../../store/actions/TransactionsActions";
import { getEventSellStats } from "../../../../../store/actions/eventsActions";
import { getPaymentTypeLabel, paymentTypes } from "../../../../../constants/paymentType.ts";

import "react-table-hoc-fixed-columns/lib/styles.css";
import "react-table/react-table.css";
import "./EventTransactions.sass";
import { sortTypes } from "../../../../../constants/transactions.ts";
import debounce from "lodash.debounce";

const MySwal = withReactContent(Swal);

export const Transactions = ({
  transactions,
  getTransactions,
  approveTransaction,
  deleteTransaction,
  editTransaction,
  clearTransactions,
  deleteSeatsTransaction,
  cancelOfferTransaction,
  currentEvent,
  token,
  match: { params },
}) => {
  const [user, setUser] = useState({});
  const [searchQuery, setSearchQuery] = useState("");
  const [requestedSearchQuery, setRequestedSearchQuery] = useState("");
  const [searchByInputNumber, setSearchByInputNumber] = useState(false);
  const [searchPaymentStatus, setSearchPaymentStatus] = useState(null);
  const [sortingType, setSortingType] = useState(sortTypes.dateDescending);
  const [isActionLoading, setActionLoading] = useState(false);

  const [currentPageNumber, setCurrentPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(500);
  const [dataToDisplay, setDataToDisplay] = useState([]);

  const event_id = params.event_id || params.id;
  const {
    transactionsList,
    transactionsTotal,
    firstTransactionsLoading,
    transactionsLoading,
  } =
    transactions;

  const ReactTableFixedColumns = useMemo(
    () => withFixedColumns(ReactTable),
    []
  );

  const [showMenuActions, hideMenuActions] = useShowActionsMenu();

  const [page, handleTableScroll, resetPage] = useLazyLoadOnTableScroll(
    pageSize,
    transactionsTotal
  );

  const handleCurrentPageChanged = (value) => {
    setCurrentPageNumber(value);
  };

  const handlePageSizeChanged = (value) => {
    setPageSize(value);
  };

  const goToCurrentPage = () => {
    const start = currentPageNumber * pageSize;
    const end = (currentPageNumber + 1) * pageSize;

    setDataToDisplay(transactionsList.slice(start, end));
  };

  const refreshDataToDisplay = () => {
    if (currentPageNumber === 0) {
      goToCurrentPage();
    } else {
      setCurrentPageNumber(0);
    }
  };

  const handleTransactionsTableScroll = (e) => {
    handleTableScroll(e);
    hideMenuActions();
  };

  const changeSortingType = (type) => {
    resetPage();
    clearTransactions();
    setSortingType(type);
  };

  const handleDebonceFn = (value) => {
    setRequestedSearchQuery(value);
  };

  const debounceFn = useCallback(debounce(handleDebonceFn, 1500), []);
  
  const changeSearchInputHandler = (e) => {
    resetPage();
    setSearchQuery(e.target.value);
    debounceFn(e.target.value);
  };

  const changeSearchByInputNumberHandler = () => {
    resetPage();
    setSearchByInputNumber(v => !v);
  };

  const changeSearchPaymentStatusHandler = (item) => {
    resetPage();
    setSearchPaymentStatus(item);
  };

  const loadUser = () => {
    const currentUser = JSON.parse(localStorage.getItem("user"));
    setUser(currentUser);
  };

  const getEventTransactions = (userToken) =>
    getTransactions(
      userToken,
      requestedSearchQuery,
      searchByInputNumber,
      searchPaymentStatus == null ? [] : [searchPaymentStatus.value],
      event_id,
      "",
      page,
      pageSize,
      sortingType
    );

  const showApproveTransactionModal = (transaction) => {
    MySwal.fire({
      html: (
        <ApproveTransactionModal
          token={user.token}
          transactionId={transaction.id}
          approveTransaction={approveTransaction}
          getEventTransactions={getEventTransactions}
        />
      ),
      showConfirmButton: false,
      customClass: "approve-user-modal",
    });
  };

  const showEditTransactionModal = (transaction) => {
    MySwal.fire({
      title: "Edit Information",
      html: (
        <EditTransactionModal
          token={user.token}
          editTransaction={editTransaction}
          deleteSeatsTransaction={deleteSeatsTransaction}
          transaction={transaction}
          getEventTransactions={getEventTransactions}
        />
      ),
      showConfirmButton: false,
      customClass: "edit-user-modal",
    });
  };

  const showRefundTicketsModal = (transaction) => {
    MySwal.fire({
      title: "Refund tickets",
      html: 
          <RefundTicketsModal
            token={user.token}
            transaction={transaction}
            setActionLoading={setActionLoading}
            getEventTransactions={getEventTransactions}
          />,
      showConfirmButton: false,
      customClass: "refund-tickets-modal",
    });
  };

  const resendTicketsHandler = (transaction) => {
    axios
      .post(`/ManageTransactions/ResendTicket/${transaction.id}`, null, {
        headers: getHeaders(user.token),
      })
      .then((response) => {
        if (response.status === 200) {
          if (response.data) {
            toast.success("Tickets successfully resent");
          } else {
            toast.error("Tickets not sent");
          }
          return;
        }

        throw new Error(response.data.errorMessage);
      })
      .catch((err) => {
        toast.error("Tickets not sent");
      });
  };

  const resendInvoiceHandler = (transaction) => {
    axios
      .post(
        `/ManageTransactions/ResendInvoice/${transaction.id}`,
        null,
        {
          headers: getHeaders(user.token),
        }
      )
      .then((response) => {
        if (response.status === 200) {
          toast.success("Invoice successfully resent");
          return;
        }

        throw new Error(response.data.errorMessage);
      })
      .catch((err) => {
        toast.error("Invoice not sent");
      });
  };

  const showLinkCopied = (link) => {
    ClipboardJS.copy(link);

    toast.dismiss(257);
    toast.success("Link was copied to clipboard.", {
      toastId: 257,
    });
  };

  const copyOfferLinkHandler = (transaction) => {
    showLinkCopied(
      `${process.env.REACT_APP_PUBLIC_URL}/offers/${transaction.id}`
    );
  };

  const copyTicketLinkHandler = (transaction) => {
    console.log(transaction)
    showLinkCopied(
      `${process.env.API_URL}/Transaction/Ticket/${transaction.ticketResultGuid}`
    );
  };

  const cancelOfferHandler = (transaction) => {
    MySwal.fire({
      title: "Are you sure you want to cancel offer?",
      text: "It’s impossible to take step back then",
      showCancelButton: true,
      cancelButtonText: "Cancel",
      confirmButtonText: "Confirm",
      reverseButtons: true,
      customClass: "confirm-cancel-offer-modal",
    }).then((result) => {
      if (result) {
        cancelOfferTransaction(user.token, transaction);
      }
    });
  };

  const downloadReportHandler = () => {
    const { id, name } = currentEvent;
    const fileName =
      name.toLowerCase().replaceAll(" ", "_") + "_transactions_report";

    axios({
      method: "GET",
      url: process.env.API_URL + `/Events/${id}/ByPaidTransactionsReport`,
      responseType: "arraybuffer",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        loadFile(
          response.data,
          `${fileName}_${moment().format("MM_DD_HH_mm")}.csv`
        );
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    if (user.token) {
      getEventTransactions(user.token);
    }
  }, [user, page, requestedSearchQuery, searchPaymentStatus, sortingType, searchByInputNumber]);

  useEffect(() => {
    clearTransactions();
    loadUser();
  }, []);

  useEffect(() => {
    refreshDataToDisplay();
  }, [transactionsList, pageSize]);

  useEffect(() => {
    goToCurrentPage();
  }, [currentPageNumber]);

  const getLabelStyle = (state, rowInfo, column) => ({
    style: {
      marginTop: "6px",
    },
  });

  const getFlexStyle = () => ({
    style: {
      padding: "18px 5px 15px",
      display: "flex",
      alignItems: "start",
    },
  });

  const getStatusStyle = () => ({
    style: {
      marginTop: "3px",
      paddingTop: "20px",
      display: "flex",
      alignItems: "start",
    },
  });

  const columns = [
    {
      Header: "Name",
      accessor: "fullName",
      resizable: false,
      getProps: getLabelStyle,
      width: 220,
      Cell: ({ row: { _original: { fullName }}}) => (
        <span title={fullName}>{fullName}</span>
      ),
    },
    {
      Header: "Email",
      accessor: "buyerEmail",
      resizable: false,
      getProps: getLabelStyle,
      width: 220,
      Cell: ({ row: { _original: { buyerEmail, id }}}) => (
        <span kew={id} title={buyerEmail}>{buyerEmail}</span>
      ),
    },
    {
      Header: "Ticket Type",
      accessor: "seatsDescription",
      resizable: false,
      width: 160,
      getProps: getLabelStyle,
      Cell: ({ row: { _original: { seats } } }) => (
        seats.map(({ ticketTemplate: { ticketName} }) => (
          <div className="td-content" title={ticketName}>
            {ticketName}
          </div>
        ))
      ),
    },
    {
      Header: "Seats",
      accessor: "seatsDescription",
      resizable: false,
      width: 130,
      getProps: getLabelStyle,
      Cell: ({ row: { _original: { seats } } }) => (
        seats.map(({ area, side, row, seat, id }) => {
          if (area && side && row && seat) {
            return (
              <div>{`${area}-${side}-${row}-${seat}`}</div>
            );
          };
          return ""
        })
      ),
    },
    {
      Header: () => (
        <div className="payment-status" onClick={() => changeSortingType(sortingType === sortTypes.statusAscending ? sortTypes.statusDescending : sortTypes.statusAscending)}>
          <span>Status</span>
          <Icon name="arrow-up-down" />
        </div>
      ),
      resizable: false,
      width: 135,
      getProps: getStatusStyle,
      Cell: ({
        row: {
          _original: {
            transactionErrorCode,
            transactionErrorMessage,
            paymentStatus,
            isOfferCanceled,
          },
        },
      }) => {
        const description =
          paymentStatus === 2
            ? transactionErrorMessage
              ? `${transactionErrorCode}: ${transactionErrorMessage}`
              : ""
            : "";

        const currentStatus = isOfferCanceled ? 3 : paymentStatus;

        return (
          <StatusLabel statusValue={currentStatus} description={description} />
        );
      },
    },
    {
      Header: "Payment Type",
      resizable: false,
      width: 130,
      getProps: getLabelStyle,
      Cell: ({ row: { _original: transaction } }) => <>{getPaymentTypeLabel(transaction.paymentType)}</>
    },
    {
      Header: "Transaction ID",
      accessor: "id",
      resizable: false,
      width: 320,
      getProps: getLabelStyle,
      Cell: ({ row: { _original: { id }}}) => (
        <span title={id}>{id}</span>
      ),
    },
    {
      Header: "Payment ID",
      accessor: "transactionId",
      resizable: false,
      width: 180,
      getProps: getLabelStyle,
    },
    {
      Cell: ({ row: { _original: transaction } }) => (
        <TransactionActions
          transaction={transaction}
          showApproveTransactionModal={showApproveTransactionModal}
          showEditTransactionModal={showEditTransactionModal}
          showRefundTicketsModal={showRefundTicketsModal}
          resendTicketsHandler={resendTicketsHandler}
          resendInvoiceHandler={resendInvoiceHandler}
          copyOfferLinkHandler={copyOfferLinkHandler}
          copyTicketLinkHandler={copyTicketLinkHandler}
          cancelOfferHandler={cancelOfferHandler}
          allowMaximize={(transaction.seats.length > 2) || (transaction.paymentType === paymentTypes.delayedPayment)}
        />
      ),
      getProps: getFlexStyle,
      width: 120,
      fixed: "right",
    },
    {
      Header: () => {
        return <div className="payment-status" onClick={() => changeSortingType(sortingType === sortTypes.dateAscending ? sortTypes.dateDescending : sortTypes.dateAscending)}>
          <span>Creation Date</span>
          <Icon name="arrow-up-down" />
        </div>
      },
      resizable: false,
      width: 160,
      getProps: getLabelStyle,
      Cell: ({ row: { _original: transaction } }) => {
        const date = new Date(transaction.createdAtUtc)
        return <>{
          date.toLocaleDateString()
        } {date.toLocaleTimeString()}</>
      }
    },
    {
      Header: () => {
        return <div className="payment-status" onClick={() => changeSortingType(sortingType === sortTypes.paymentDateAscending ? sortTypes.paymentDateDescending : sortTypes.paymentDateAscending)}>
          <span>Payment Date</span>
          <Icon name="arrow-up-down" />
        </div>

      },
      resizable: false,
      width: 160,
      getProps: getLabelStyle,
      Cell: ({ row: { _original: transaction } }) => {
        const date = new Date(transaction.paymentDate)

        if (date.getTime() < new Date(1969, 1, 1, 0, 0, 0, 0).getTime()) {
          return <></>
        }

        return <>{
          date.toLocaleDateString()
        } {date.toLocaleTimeString()}</>
      }
    },
    {
      Header: "Invoice Number",
      resizable: false,
      getProps: getLabelStyle,
      width: 130,
      Cell: ({ row: { _original: transaction } }) => <>{transaction.invoice && transaction.invoice.number}</>
    },
    {
      Header: "Additional Info",
      resizable: false,
      getProps: getLabelStyle,
      width: 320,
      Cell: ({ row: { _original: transaction } }) => {
        return <>
          {transaction.paymentType === paymentTypes.delayedPayment
            ? <>
              <p>Company name: {transaction.companyName} </p>
              <p>Company VAT: {transaction.companyVatNumber} </p>
              <p>Invoice amount: {transaction.amount} {transaction.currency || 'RSD'}</p>
            </>
            : <>
              <ol>{transaction.info.map(i => <li style={{ margin: '2px' }}>{i.description}</li>)}</ol>
            </>
          }
        </>
      }
    },
  ];

  
  const customSelectStyles = {
    control: (base) => ({
      ...base,
      height: "46px",
      "min-height": "46px",
      cursor: "pointer",
    }),
    placeholder: (base) => ({
      ...base,
      color: "#ABABAB",
    }),
  };

  let emptyStateText = "There are no transactions";

  if (transactionsTotal === 0 && requestedSearchQuery) {
    emptyStateText = "Sorry, no results were found";
  }

  return (
    <div className="new-event__transactions event-transactions">
      <div className="searchPanel">
        <Select
          isClearable
          options={paymentStatuses}
          placeholder="Select status"
          name="searchPaymentStatus"
          classNamePrefix="custom-select"
          value={searchPaymentStatus}
          onChange={changeSearchPaymentStatusHandler}
          className="seasonpass__select searchPanel__select"
          styles={customSelectStyles}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary: "#6071B5",
              primary25: "#F2F4FE",
              primary50: "#F2F4FE",
              primary75: "#F2F4FE",
            },
          })}
        />

        <input
          className="searchPanel__search input-behavior"
          type="text"
          placeholder="Search"
          value={searchQuery}
          onChange={changeSearchInputHandler}
        />

        <CheckBox checked={searchByInputNumber}
          id='searchByInputNumber'
          name="searchByInputNumber"
          onChange={changeSearchByInputNumberHandler}>
          <span className="input-behavior">
            Search by invoice number
          </span>
        </CheckBox>

        <button
          className='btn-primary-new admin'
          onClick={downloadReportHandler}
        >
          Download Report
        </button>
      </div>

      {(firstTransactionsLoading || transactionsLoading || isActionLoading) ? (
        <div className="event-transactions__loader">
          <NewLoader />
        </div>
      ) : transactionsList.length ? (
        <>
          <ReactTableFixedColumns
            data={dataToDisplay}
            columns={columns}
            manual
            showPagination={true}
            pageSizeOptions={[100, 200, 500, 1000, 100000]}
            minRows={0}
            page={currentPageNumber}
            pageSize={pageSize}
            pages={Math.ceil(transactionsList.length / pageSize)}
            onPageChange={handleCurrentPageChanged}
            onPageSizeChange={handlePageSizeChanged}
            sortable={false}
            getTrProps={() => ({
              style: {
                alignItems: "stretch",
              },
            })}
            getTableProps={() => ({
              onScroll: handleTransactionsTableScroll,
            })}
          />
        </>
      ) : (
        <div className="events__empty-state">
          <PageTitle>{emptyStateText}</PageTitle>
          <img src="/images/img-empty-events.svg" alt="Empty transactions" />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = ({ auth, transactions, events }) => ({
  auth,
  token: auth.user.token,
  transactions,
  currentEvent: events.currentEvent,
});

const mapDispatchToProps = (dispatch) => ({
  getTransactions: (
    userToken,
    searchQuery,
    searchByInputNumber,
    paymentStatuses,
    eventId,
    transactionStatus,
    page,
    pageSize,
    sortingType
  ) =>
    dispatch(
      getTransactions(
        userToken,
        searchQuery,
        searchByInputNumber,
        paymentStatuses,
        eventId,
        transactionStatus,
        page,
        pageSize,
        sortingType
      )
    ),
  approveTransaction: (userToken, id, makeFiscalization, onConfirm) =>
    dispatch(approveTransaction(userToken, id, makeFiscalization, onConfirm)),
  deleteTransaction: (userToken, id) =>
    dispatch(deleteTransaction(userToken, id)),
  editTransaction: (userToken, transaction) =>
    dispatch(editTransaction(userToken, transaction)),
  clearTransactions: () => dispatch(clearTransactions()),
  deleteSeatsTransaction: (userToken, transaction, seatIds) =>
    dispatch(deleteSeatsTransaction(userToken, transaction, seatIds)),
  cancelOfferTransaction: (userToken, transaction) =>
    dispatch(cancelOfferTransaction(userToken, transaction)),
  getEventSellStats: (eventId, token) =>
    dispatch(getEventSellStats(eventId, token)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Transactions)
);
