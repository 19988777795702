import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import moment from "moment";
import {
  sendResalePurposes,
} from "../../../../../store/actions/eventsActions";
import { loadFile } from "../../../../../helpers/loadFile";
import Icon from "../../../../../components/UI/Icon/Icon";

import "./EventReports.sass";

class EventReports extends Component {
  downloadExcelReport = (type) => {
    const { currentEvent } = this.props;
    let url = process.env.API_URL;
    const id = currentEvent.id;
    const name = currentEvent.name;
    let fileName = name.toLowerCase().replaceAll(" ", "_");
    let httpMethod = "GET";
    let ext = "csv";
    let body = undefined;

    switch (type) {
      case "tickets-report":
        httpMethod = "POST";
        fileName += "_tickets_report";
        url += `/EventReports/ByTicketTemplatesAtEvent?eventId=${id}&asCsv=true`;
        break;
      case "tickets-report2":
        fileName += "_tickets_report";
        url += `/Events/${id}/ByTicketTemplateReport`;
        break;
      case "transactions-report":
        fileName += "_transactions_report";
        url += `/Events/${id}/ByPaidTransactionsReport`;
        break;
      case "scanning-report":
        httpMethod = "POST";
        body = `{
                "fromTimeUtc": "${moment(currentEvent.startDate)
            .utcOffset(0, true)
            .add("hours", -6)
            .toISOString()}",
                "toTimeUtc": "${moment(currentEvent.endDate)
            .utcOffset(0, true)
            .add("hours", 6)
            .toISOString()}"
                }`;
        fileName += "_scan_report";
        url += `/EventReports/ByScanteamAtPeriod?asCsv=true`;
        break;
      default:
        fileName += "_scan_report";
        ext = "xls";
        url += `/ScanActions?eventId=${id}`;
        break;
    }

    axios({
      method: httpMethod,
      url,
      responseType: "arraybuffer",
      headers: {
        Authorization: `Bearer ${this.props.user.token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      data: body,
    })
      .then((response) => {
        loadFile(
          response.data,
          `${fileName}_${moment().format("MM_DD_HH_mm")}.${ext}`
        );
      })
      .catch((error) => console.log(error));
  };

  render() {
    const { currentEvent } = this.props;

    return (
      <div className="new-event__reports reports">
        <div
          className="reports__wrapper"
          onClick={() => this.downloadExcelReport("tickets-report")}
        >
          <Icon name="excel" />
          <button className="reports__button">
            Tickets Report
          </button>
        </div>
        <div
          className="reports__wrapper"
          onClick={() =>
            this.downloadExcelReport("transactions-report")
          }
        >
          <Icon name="excel" />
          <button className="reports__button">
            Transactions Report
          </button>
        </div>
        <div
          className="reports__wrapper"
          onClick={() => this.downloadExcelReport("scanning-report")}
        >
          <Icon name="excel" />
          <button className="reports__button">
            Scanning Report
          </button>
        </div>

        <div className="all-and-scanned">
            <div className="add-event__column">
              <div className="add-event__block reports__block">
                <label>All tickets</label>
                <span className="add-event__block__count">
                  {currentEvent.allTickets}
                </span>
              </div>
              <div className="add-event__block reports__block">
                <label>Scanned tickets</label>
                <span className="add-event__block__count">
                  {currentEvent.scannedTickets}
                </span>
              </div>
            </div>
          </div>
      </div>
    );
  }
};

const mapStateToProps = ({
  auth: { user },
  events: { currentEvent },
}) => ({ currentEvent, user });

const mapDispatchToProps = (dispatch) => ({
  sendResalePurposes: (eventId) => dispatch(sendResalePurposes(eventId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EventReports);
